import { ActionType } from "redux-promise-middleware";
import {
  FETCH_ALL_EVENTS,
  FETCH_SINGLE_EVENT_INFO,
  FETCH_PENDING_EVENTS,
  FETCH_EVENT_STATISTICS,
  PRINT_OFFLINE_TICKETS,
  DOWNLOAD_OFFLINE_TICKETS,
  OFFLINE_BATCH_TICKETS
} from "./types";

const initialState = {
  loading: false,
  data: {},
};

export default class CampaignReducers {
  static allEventsReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_ALL_EVENTS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_ALL_EVENTS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_ALL_EVENTS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static singleEventReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_SINGLE_EVENT_INFO}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_SINGLE_EVENT_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_SINGLE_EVENT_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static pendingEventsReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_PENDING_EVENTS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_PENDING_EVENTS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_PENDING_EVENTS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static eventStatisticsReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_EVENT_STATISTICS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_EVENT_STATISTICS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_EVENT_STATISTICS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static eventPrintOfflineTickets(state = initialState, action){
    switch(action.type){
      case `${PRINT_OFFLINE_TICKETS}_PENDING`:
        return {
          ...state,
          loading:true
        };
      case `${PRINT_OFFLINE_TICKETS}_FULFILLED`:
        return {
          ...state,
          loading:false,
          data:action.payload
        };
      case `${PRINT_OFFLINE_TICKETS}_REJECTED`:
        return{
          ...state,
          loading:false,
          data: action.payload
        };
      default:
        return state;
    }
  }

  static downloadOfflineTickets(state = initialState, action){
    switch(action.type){
      case`${DOWNLOAD_OFFLINE_TICKETS}_PENDING`:
      return {
        ...state,
        loading:true,
      }
      case `${DOWNLOAD_OFFLINE_TICKETS}_FULFILLED`:
        return {
          ...state, 
          loading:false,
          data: action.payload
        }
      case `${DOWNLOAD_OFFLINE_TICKETS}_REJECTED`:
        return {
          ...state,
          loading:false,
          data: action.payload
        }
      default:
        return state
    }
  }

  static offlineTicketsBatches(state = initialState, action) {
    switch(action.type){
      case `${OFFLINE_BATCH_TICKETS}_PENDING`:
        return {
          ...state,
          loading:true,
        }
      case `${OFFLINE_BATCH_TICKETS}_FULFILLED`:
        return {
          ...state,
          loading:false,
          data: action.payload,
        }
      case `${OFFLINE_BATCH_TICKETS}_RJECTED`:
        return {
          ...state,
          loading:false,
          data: action.payload
        }
      default:
        return state
    }
  }
}
