import React, { useState, useEffect } from "react";
import Loading from "../../../../../components/isLoading";
import MultiRecordsChartComponent from "../../../../components/stats/MultiRecordsChart";
import NoContent from "../../../../../components/NoContent";
import { formatConcertChartData } from "../../../../../helpers/formatChartDataConcert";
import { whiteColor } from "../../../../../assets/styles/base";
import MenuComponent from "../../../../../components/Menu";

export default (props) => {
  const { chartData, isUpdating, role } = props;
  const [state, setState] = useState({
    activeMenuName: "tier name",
    activeMenuIdentifier: "tier name",
    activeMenuRevenue: "tier nameRevenue",
    activeCurrency: "rwf",
    dataToDisplay: {
      data: {},
      revenues: {},
    },
    initialData: {
      data: {},
      revenues: {},
    },
    currencyData: {
      revenues: {},
    },
  });

  const onMenuClose = (identifier) => {
    props.changeContestantsForSelectedMenu("", []);
    setState({
      ...state,
      activeMenuName: identifier.charAt(0).toUpperCase() + identifier.slice(1),
      activeMenuIdentifier: identifier,
      activeMenuRevenue: `${identifier}Revenue`,
      hideRevenueChart: identifier === "tickets",
    });
  };
  const menuList = ["Tier Name", "Dates", "Buckets", "order size", "status", "sources", "vendors"];

  useEffect(() => {
    if (chartData.TierSummary) {
      setState({
        ...state,
        initialData: {
          data: formatConcertChartData(
            chartData,
            state.activeMenuIdentifier,
          ),
          revenues: formatConcertChartData(
            chartData,
            state.activeMenuRevenue,
            role
          ),
        },

        dataToDisplay: {
          data: formatConcertChartData(
            chartData,
            state.activeMenuIdentifier,
            state.activeCurrency
          ),
          revenues: formatConcertChartData(
            chartData,
            state.activeMenuRevenue,
            state.activeCurrency,
            role
          ),
        },
      });
    }
  }, [chartData, state.activeMenuIdentifier]);

  useEffect(() => {
    if (chartData.TierSummary) {
      setState({
        ...state,
        currencyData: {
          ...state.currencyData,
          revenues: {
            ...formatConcertChartData(
              chartData,
              state.activeMenuRevenue,
              role
            ),
            datasets: formatConcertChartData(
              chartData,
              state.activeMenuRevenue,
              role
            ).datasets.filter(
              (elt) =>
                elt.label.toLowerCase().includes(state.activeCurrency) ||
                elt.label === "Percentage"
            ),
          },
        },
      });
    }
  }, [state.activeCurrency, state.dataToDisplay.revenues]);

  const showChosenData = (selectLabels) => {
    const selectLabelsArr = selectLabels.map((elt) => elt.label);
    const selectDatasetsArr = selectLabels.map((elt) => {
      return({
      tickets: elt.data.votes,
      revenues: elt.data.revenues,
    })});

    setState((prevState) => {
      return({
      ...prevState,

      dataToDisplay: {
        data: {
          ...prevState.dataToDisplay.data,
          datasets: [
            {
              ...prevState.initialData.data.datasets[0],
              data: prevState.initialData.data.datasets[0].data
                .map((item, index) => {
                  const itemIsSelected = selectDatasetsArr.find(
                    (obj) => obj.votes === item
                  );

                  if (itemIsSelected) {
                    return item;
                  }
                })
                .filter((elt) => !!elt),
            },
          ],

          labels: prevState.initialData.data.labels
            .map((item) => {
              if (selectLabelsArr.includes(item)) {
                return item;
              }
            })
            .filter((elt) => !!elt),
        },
        revenues: {
          ...prevState.dataToDisplay.revenues,
          datasets: [
            {
              ...prevState.initialData.revenues.datasets[0],
              data: prevState.initialData.revenues.datasets[0].data
                .map((item, index) => {
                  const itemIsSelected = selectDatasetsArr.find(
                    (obj) => obj.revenues === item
                  );

                  if (itemIsSelected) {
                    return item;
                  }
                })
                .filter((elt) => !!elt),
            },
          ],

          labels: prevState.initialData.revenues.labels
            .map((item) => {
              if (selectLabelsArr.includes(item)) {
                return item;
              }
            })
            .filter((elt) => !!elt),
        },
      },
    })});
  };
  const currencyMenuList = ["RWF", "USD", "EUR"];
  const onCurrencyMenuClose = (identifier) => {
    setState({
      ...state,
      activeCurrency: identifier,
    });
  };

  return isUpdating ? (
    <Loading />
  ) : (
    <>
      {state.dataToDisplay.data.labels ? (
        <>
          <MenuComponent
            onMenuClose={onMenuClose}
            menuList={menuList}
            menuTitle={state.activeMenuName}
            showChosenData={showChosenData}
            data={state.initialData}
            noSecondarySelect
            // noSecondarySelect={
            //   state.activeMenuIdentifier === "votes" ||
            //   state.activeMenuIdentifier === "buckets"
            // }
          />

          <MultiRecordsChartComponent
            data={
              state.dataToDisplay.data.labels[0]
                ? state.dataToDisplay.data
                : state.initialData.data
            }
            background={whiteColor}
            title={state.activeMenuName}
          />
          {!state.hideRevenueChart && (
            <>
              <MenuComponent
                noSecondarySelect
                onMenuClose={onCurrencyMenuClose}
                menuList={currencyMenuList}
                menuTitle={state.activeCurrency}
                // showChosenData={showChosenData}
                data={state.initialData}
              />

              <MultiRecordsChartComponent
                data={
                  state.currencyData.revenues.labels
                    ? state.currencyData.revenues
                    : state.dataToDisplay.revenues
                }
                background={whiteColor}
                title="Revenues"
              />
            </>
          )}
          {state.activeMenuIdentifier === "tickets" && (
            <MultiRecordsChartComponent
              data={
                state.currencyData.revenues.labels
                  ? state.currencyData.revenues
                  : state.dataToDisplay.revenues
              }
              background={whiteColor}
              title="Tickets Percentage"
            />
          )}
        </>
      ) : (
        <NoContent title={"No Content ."} />
      )}
    </>
  );
};
