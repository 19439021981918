import { Avatar } from "@material-ui/core";
import React from "react";
import { createData } from "../../../helpers/helperMethods";
import DataTableComponent from "../../components/data/DataTableComponent";

export default (props) => {
  const { isUpdating, data, notActive, noContentCustomElements } = props;

  const headCells = [
    {
      id: "name",
      rightAlign: false,
      label: "Name",
    },
    {
      id: "category",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Category",
    },
    {
      id: "payment",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Payment",
    },
    {
      id: "startDate",
      rightAlign: true,
      label: "Start Date",
    },
    {
      id: "endDate",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "End Date",
    },
    {
      id: "venue",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Venue",
    },
  ];


  const rows = data
    ? data.map((elt) =>
        createData(
          elt.id,
          {
            content1: (
              <Avatar
                alt={elt.name || "Img"}
                src={elt.coverPhoto}
                variant="rounded"
              />
            ),
            content2: elt.name,
          },
          elt.category,
          elt.payment?.required ? "Required" : "Not Required",
          elt?.startDate,
          elt?.endDate,
          elt.venue || "Unset"
        )
      )
    : [];

  return (
    <DataTableComponent
      isClickable
      notActive={notActive}
      hasLongTitles
      isUpdating={isUpdating}
      rows={rows}
      headCells={headCells}
      title="Events"
      generalUrl="/events"
      noContentCustomElements={noContentCustomElements}
    />
  );
};
