import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ProgressiveImage from 'react-progressive-image';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import itemInfoStyles from '../../../assets/styles/components/itemInfoStyles';
import Loading from '../../../components/isLoading';
import SingleItemMenu from '../../../components/Menu/SingleItemMenu';
import ConfirmModel from '../../../components/Dialogs/ConfirmationDialog';

const defaultImg = 'https://www.careersinafrica.com/wp-content/uploads/2020/05/placeholder.png';

const styles = makeStyles((theme) => itemInfoStyles(theme));

export default (props) => {
  const {
    content,
    isLoading,
    options
  } = props;

  const classes = styles();
  
  return (
    <div>
      <ConfirmModel {...options.confirmModelOptions} />
      {isLoading ? (
        <Loading />
      ) : (
        <div>
            <>
              <Grid container className={classes.topSection}>
                <Grid item xs={12} container justify='flex-end'>
                  <ProgressiveImage
                    src={content.userOrganization.image}
                    placeholder={props.placeholder || defaultImg}
                  >
                    {(src, loading) => (
                      <img
                        style={{
                          opacity: loading ? 0.7 : 1,
                          objectFit: 'cover',
                          borderRadius: '6px',
                        }}
                        src={src}
                        alt='No cover'
                        className={classes.image}
                      />
                    )}
                  </ProgressiveImage>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  direction='column'
                  spacing={2}
                  className={classes.infoSection}
                >
                  <Grid item className={classes.textInfo}>
                    <Typography variant='h4' className={classes.name}>
                      {content.name}
                    </Typography>
                    <Typography variant='body1' className={classes.texts}>
                      Organization: {content.userOrganization.name}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.actions}>
                    <Grid container spacing={2} justify='flex-end'>
                        <SingleItemMenu
                          horiz
                          background
                          {...options.actions}
                          item={content}
                        />
                      
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
        </div>
      )}
    </div>
  );
};
