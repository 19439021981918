import reducers from "./reducers";

const campaignsReducers = {
  allCampaigns: reducers.allCampaignsReducer,
  singleCampaign: reducers.singleCampaignReducer,
  campaignsCountryInfo: reducers.campaignsCountryInfoReducer,
  campaignsDemographicsInfo: reducers.campaignsDemographicsInfoReducer,
  createCampaign: reducers.createCampaignReducer,
  pendingCampaigns: reducers.pendingCampaignsReducer,
};

export { campaignsReducers };
