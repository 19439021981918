import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CustomCard from "../../../components/Card/CustomCard";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import TextField from "../../../components/CustomInput/TextField";
import CustomDialog from "../../../components/Dialogs/CustomDialog";
import InviteClientUser from "./InviteClientComponent";
import HorizontalListItem from "../List/HorizontalListItem";
import { useHistory } from "react-router";
import CustomButton from "../../../components/Buttons/customButton";
import SearchBar from "../../../components/searchBar";

const styles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  listItem: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.02)",
      cursor: "pointer",
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

export default ({
  clients,
  app,
  onClientCreate,
  sendClientInvite,
  onEnableDisableStats,
}) => {
  const classes = styles();
  const history = useHistory();

  const [state, setState] = useState({
    createClientModel: false,
    inviteClientModel: false,
    selectedClient: {},
    searchTerm: "",
    dataToDisplay: clients,
  });

  useEffect(() => {
    setState({
      ...state,
      dataToDisplay: state.searchTerm
        ? clients.filter((elt) =>
            elt.name.toLowerCase().includes(state.searchTerm.toLowerCase())
          )
        : clients,
    });
  }, [state.searchTerm]);

  const toggleCreateClient = () => {
    setState({ ...state, createClientModel: !state.createClientModel });
  };
  const toggleInviteClient = () => {
    setState({
      dataToDisplay: clients,
      inviteClientModel: !state.inviteClientModel,
    });
  };
  const onInputsChange = (ev) => {
    setState({ ...state, [ev.target.name]: ev.target.value });
  };
  const onCreateClient = () => {
    toggleCreateClient();
    onClientCreate({
      clientName: state.clientName,
      website: state.website,
    });
  };
  const onInviteClient = (inviteData) => {
    toggleInviteClient();
    const invite = {
      client: state.selectedClient,
      data: inviteData,
    };
    sendClientInvite(invite);
  };

  const setInviteState = (client) => {
    setState({
      ...state,
      inviteClientModel: true,
      selectedClient: client,
    });
  };
  const onEltClick = (id) => history.push(`/orgs/${id}`);
  const onSearch = (text) => {
    setState((prevState) => ({
      ...prevState,
      searchTerm: text,
    }));
  };

  return (
    <Box>
      <CustomCard
        body={
          <Box>
            <div style={{ padding: "10px" }}>
              <SearchBar
                value={state.searchTerm}
                placeholder="Search Organization"
                onChange={onSearch}
              />
            </div>
            <Grid container justify="flex-end">
              <CustomButton color="primary" onClick={toggleCreateClient}>
                Create organization
              </CustomButton>
            </Grid>
            <List className={classes.root}>
              {state.dataToDisplay.map((client) => (
                <div key={client.id}>
                  <HorizontalListItem
                    isSmall
                    title={client.name}
                    subTitle={
                      <div>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          Users: {client.users}
                          &nbsp;&nbsp;
                          {client.website && (
                            <a href={client.website} className={classes.link}>
                              Visit
                            </a>
                          )}
                        </Typography>
                        <br></br>Created: {moment(client.createdAt).fromNow()}
                      </div>
                    }
                    actionMenu={[
                      {
                        primary: "View Details",
                        link: `/orgs/${client.id}`,
                      },
                      {
                        primary: "Invite user",
                        onClick: () => setInviteState(client),
                      },
                      {
                        primary: client.canViewStats
                          ? "Disable Stats"
                          : "Enable Stats",
                        onClick: () =>
                          onEnableDisableStats(client.id, !client.canViewStats),
                      },
                    ]}
                    imgUrl={client.logoSrc}
                    onClick={() => onEltClick(client.id)}
                  />
                  <Divider style={{ margin: "0 5px" }} />
                </div>
              ))}
            </List>
            <CustomDialog
              open={state.createClientModel || false}
              fullWidth
              title="Create new organization"
              okText="Create"
              onCancel={toggleCreateClient}
              onConfirm={onCreateClient}
              content={
                <Box>
                  <TextField
                    label="organization name"
                    name="clientName"
                    fullWidth
                    onChange={onInputsChange}
                  />
                  <TextField
                    label="Website url"
                    fullWidth
                    name="website"
                    type="url"
                    onChange={onInputsChange}
                  />
                </Box>
              }
            />
          </Box>
        }
      />
      {state.inviteClientModel && (
        <InviteClientUser
          onCancel={toggleInviteClient}
          onConfirm={onInviteClient}
          clientName={
            state.selectedClient.clientName || state.selectedClient.name
          }
          open={state.inviteClientModel}
          roles={app.roles || []}
        />
      )}
    </Box>
  );
};
