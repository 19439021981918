import React, { useEffect } from "react";
import HeaderSelect from "../../../components/CustomSelect/HeaderSelect";
import { useDispatch, useSelector } from "react-redux";
import { selectPublisherAction } from "../../../redux/publishers/index";
import reduxAction from "../../../redux/action";
import { FETCH_PUBLISHERS } from "../../../redux/publishers/types";

export default (props) => {
  const dispatch = useDispatch();
  const selectedPublishersStore = useSelector((state) => state.selectPublisher);
  const allPublishersStore = useSelector((state) => state.allPublishers);

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: "/publishers",
        actionType: FETCH_PUBLISHERS,
      })
    );
  }, []);

  const onHeaderSelect = (selectedValue) => {
    dispatch(selectPublisherAction({ selectPublisher: selectedValue }));
  };

  let headerMenus = [];

  if (allPublishersStore.data.results) {
    headerMenus = allPublishersStore.data.results.map((elt) => ({
      id: elt.id,
      name: elt.clientName || elt.name,
    }));
  }
  return (
    <HeaderSelect
      onChangeMenu={onHeaderSelect}
      value={selectedPublishersStore.selectedPublisher}
      headerMenus={headerMenus}
    />
  );
};
