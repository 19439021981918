import React, { useEffect, useState } from "react";
import Loading from "../../../components/isLoading";
import MultiRecordsDoughnutChartComponent from "../../components/stats/MultiRecordsDoughnutChart";
import NoContent from "../../../components/NoContent";
import { formatChartData } from "../../../helpers/formatChartData";
import {
  findPercentage,
  useWindowDimensions,
} from "../../../helpers/helperMethods";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { whiteColor } from "../../../assets/styles/base";
import moment from "moment";
import MultiRecordsChartComponent from "../../components/stats/MultiRecordsChart";
import HeaderSelect from "../../../components/CustomSelect/HeaderSelect";
import GridContainer from "../../../components/Grid/GridContainer";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/styles/components/chartStyle";
import reduxAction from "../../../redux/action";
import { FETCH_PUBLISHER_TECHNOLOGY_INFO } from "../../../redux/publishers/types";
import DataTableComponent from "../../components/data/DataTableComponent";
import TimePicker from "../../components/date/TimePickerComponent";
import { createData } from "../../../helpers/helperMethods";

const useStyles = makeStyles(styles);

export default (props) => {
  const classes = useStyles();

  const selectedPublishersStore = useSelector((state) => state.selectPublisher);
  const selectedPublisher = selectedPublishersStore.selectedPublisher;

  const dimensions = [{ id: "website", name: "Website" }];

  const technologyInfoStore = useSelector((store) => store.technologyInfo);
  const chartWidth = useWindowDimensions().width;

  const dispatch = useDispatch();

  const [state, setState] = useState({
    selectedDimension: { name: "website", identifier: "web" },
    selectedSecondaryDimension: "page-views-articles",
    dataFor: "page-views-articles",
  });

  const [dateState, setDateState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  let startDate = moment(
    dateState.date && dateState.date.startDate
      ? dateState.date.startDate
      : dateState.date
  )
    .subtract(6, "d")
    .format("YYYY-MM-DD");
  let endDate = moment(
    dateState.date && dateState.date.endDate
      ? dateState.date.endDate
      : dateState.date
  ).format("YYYY-MM-DD");

  if (state.daily) {
    startDate = moment(dateState.date).subtract(1, "d").format("YYYY-MM-DD");
    endDate = moment(dateState.date).subtract(1, "d").format("YYYY-MM-DD");
  } else if (state.weekly) {
    startDate = moment(dateState.date).subtract(7, "d").format("YYYY-MM-DD");
  } else if (state.monthly) {
    startDate = moment(dateState.date).subtract(1, "M").format("YYYY-MM-DD");
  } else if (state.yearly) {
    startDate = moment(dateState.date).subtract(1, "y").format("YYYY-MM-DD");
  }

  const onRangeChange = (range) => setDateState({ [range]: !state[range] });
  const onDateChange = (date) => setDateState({ ...date });

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/publishers/${selectedPublisher}/data/${state.selectedDimension.identifier}?metric=technology&&startDate=${startDate}&endDate=${endDate}`,
        actionType: FETCH_PUBLISHER_TECHNOLOGY_INFO,
      })
    );
  }, [selectedPublisher, state.selectedDimension.identifier, startDate]);

  useEffect(() => {
    if (
      !technologyInfoStore.data.results ||
      (dateState.date && dateState.date.startDate) ||
      dateState.date !== moment().format("YYYY-MM-DD") ||
      dateState.daily ||
      dateState.weekly ||
      dateState.monthly ||
      dateState.yearly
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/publishers/${selectedPublisher}/data/${state.selectedDimension.identifier}?metric=technology&&startDate=${startDate}&endDate=${endDate}`,
          actionType: FETCH_PUBLISHER_TECHNOLOGY_INFO,
        })
      );
    }
  }, [dateState]);

  const handleDimensionChange = (selected) => {
    setState((prevState) => ({
      ...state,
      selectedDimension: {
        name: selected,
        identifier:
          prevState.selectedDimension.identifier === "fb" ? "web" : "fb",
      },
    }));
  };

  const secondaryDimensions =
    state.selectedDimension.name === "facebook"
      ? [{ id: "page-impression", name: "Page Impressions" }]
      : [
          { id: "page-views-articles", name: "Page Views & Articles" },
          { id: "visits", name: "Visits" },
        ];

  const onSecondaryDimensionChange = (selected) =>
    setState({
      ...state,
      selectedSecondaryDimension: selected,
      dataFor: selected,
    });

  let data = {};
  if (technologyInfoStore.data.results) {
    if (
      state.selectedDimension.identifier === "web" &&
      technologyInfoStore.data.results.deviceCategorySummary &&
      technologyInfoStore.data.results.deviceCategorySummary[0]
    ) {
      if (state.selectedSecondaryDimension === "visits") {
        const desktopVisits =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "desktop"
          ).visits;
        const mobileVisits =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "mobile"
          ).visits;
        const tabletVisits =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "tablet"
          ).visits;

        const percentageDesktop = findPercentage(
          desktopVisits,
          desktopVisits,
          mobileVisits,
          tabletVisits
        );
        const percentageMobile = findPercentage(
          mobileVisits,
          desktopVisits,
          mobileVisits,
          tabletVisits
        );
        const percentageTablet = findPercentage(
          tabletVisits,
          desktopVisits,
          mobileVisits,
          tabletVisits
        );

        data = {
          labels: [
            `Desktop: ${percentageDesktop}%`,
            `Mobile: ${percentageMobile}%`,
            `Tablet: ${percentageTablet}%`,
          ],
          datasets: [
            {
              label: "# of Devices",
              data: [desktopVisits, mobileVisits, tabletVisits],
              backgroundColor: ["#1977D1", "#641D48", "#FF9800"],
              borderColor: ["#1977D1", "#641D48", "#FF9800"],
              borderWidth: 1,
            },
          ],
        };
      } else {
        const desktopViews =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "desktop"
          ).pageViews;
        const mobileViews =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "mobile"
          ).pageViews;
        const tabletViews =
          technologyInfoStore.data.results.deviceCategorySummary.find(
            (elt) => elt.deviceCategory === "tablet"
          ).pageViews;

        const percentageDesktop = findPercentage(
          desktopViews,
          desktopViews,
          mobileViews,
          tabletViews
        );
        const percentageMobile = findPercentage(
          mobileViews,
          desktopViews,
          mobileViews,
          tabletViews
        );
        const percentageTablet = findPercentage(
          tabletViews,
          desktopViews,
          mobileViews,
          tabletViews
        );
        data = {
          labels: [
            `Desktop: ${percentageDesktop}%`,
            `Mobile: ${percentageMobile}%`,
            `Tablet: ${percentageTablet}%`,
          ],
          datasets: [
            {
              label: "# of Devices",
              data: [desktopViews, mobileViews, tabletViews],
              backgroundColor: ["#1977D1", "#641D48", "#FF9800"],
              borderColor: ["#1977D1", "#641D48", "#FF9800"],
              borderWidth: 1,
            },
          ],
        };
      }
    }
  }

  const headCells = [
    {
      id: "name",
      rightAlign: false,
      label: "Name",
    },
    {
      id: "total-devices",
      rightAlign: true,
      label: "Total Devices",
    },
    {
      id: "visits",
      rightAlign: true,
      label: "Visits",
    },
    {
      id: "views",
      rightAlign: true,
      // doNotShowInMobile: true,
      label: "Views",
    },
  ];

  const rows = technologyInfoStore.data.results
    ? technologyInfoStore.data.results.mobileDeviceBrandSummary.map((elt) =>
        createData(
          elt.name && elt.name.toLowerCase(),
          elt.name,
          elt.modelsCount,
          elt.totalVisits,
          elt.views
        )
      )
    : [];

  return technologyInfoStore.loading && !technologyInfoStore.data.results ? (
    <Loading />
  ) : (
    <>
      <TimePicker
        startDate={startDate}
        endDate={endDate}
        onRangeChange={onRangeChange}
        onDateChange={onDateChange}
        lastSyncTime={
          moment(
            technologyInfoStore.data.results &&
              technologyInfoStore.data.results.lastSyncTime
          ).format("LLL") || "00:00 AM"
        }
      />
      {technologyInfoStore.data.results ? (
        <Grid
          container
          style={{
            background: whiteColor,
            padding: 20,
          }}
        >
          <GridContainer>
            <div className={classes.headContainer}>
              <Typography className={classes.title}>Technology</Typography>

              <span className={classes.dimensionSelector}>
                {dimensions && (
                  <HeaderSelect
                    hasSecondary
                    onChangeMenu={(selected) => handleDimensionChange(selected)}
                    value={state.selectedDimension.name}
                    headerMenus={dimensions}
                    onChangeSecondaryMenu={(selected) =>
                      onSecondaryDimensionChange(selected)
                    }
                    secondaryValue={state.selectedSecondaryDimension}
                    secondaryHeaderMenus={secondaryDimensions}
                  />
                )}
              </span>
            </div>
          </GridContainer>

          {technologyInfoStore.loading ? (
            <Loading />
          ) : (
            <>
              <GridContainer>
                <MultiRecordsDoughnutChartComponent data={data} />
              </GridContainer>
            </>
          )}
        </Grid>
      ) : (
        <NoContent title={"No Content ."} />
      )}
      <br />
      <DataTableComponent
        noSearch
        isUpdating={
          technologyInfoStore.loading && technologyInfoStore.data.results
        }
        rows={rows}
        headCells={headCells}
      />
    </>
  );
};
