import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import PulseLoader from "react-spinners/PulseLoader";
// core components
import Button from "../Buttons/customButton";
import uploadStyle from "../../assets/styles/components/uploadStyle";

const uploadDefaultImage =
  "https://www.careersinafrica.com/wp-content/uploads/2020/05/placeholder.png";
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imageError: "",
      imagePreviewUrl: this.props.defaultImage
        ? this.props.defaultImage
        : uploadDefaultImage,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultImage !== this.props.defaultImage) {
      this.setState({
        ...this.state,
        imagePreviewUrl: this.props.defaultImage
          ? this.props.defaultImage
          : uploadDefaultImage,
      });
    }
  }

  handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    if (file.type && this.props.imageTypes.includes(file.type)) {
      reader.onloadend = () => {
        if(this.props.dimension){
          const _URL = window.URL || window.webkitURL;
          const img = new Image()
          const objectUrl = _URL.createObjectURL(file);
          img.onload =  (e)=> {
            if(this.props.dimension.width!==e.currentTarget.width||this.props.dimension.height!==e.currentTarget.height){
             this.setState({
              ...this.state,
              imageError: `Image must be ${this.props.dimension.width}x${this.props.dimension.height} pixels`,
             })
            }
            _URL.revokeObjectURL(objectUrl);
          };
          img.src = objectUrl;
        }
        this.setState({
          ...this.state,
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({
        ...this.state,
        imageError: "You must use jpeg, png, jpg, gif Image formats",
      });
    }
  };
  handleSubmit = (e) => {
    this.props.imageSubmit(this.state.file);
  };

  handleClick = () => {
    this.refs.fileInput.click();
  };

  handleRemove = async () => {
    const error = await this.props.removeUploaded();
    if (!error) {
      this.setState({
        file: null,
        imagePreviewUrl: uploadDefaultImage,
      });
      this.refs.fileInput.value = null;
    }
  };
  render() {
    const {
      isForm,
      classes,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      imageError,
      isUploading,
      imageTypes,
      removing,
      isSmall,
      disabled,
    } = this.props;

    return (
      <div className={isSmall ? classes.smallRoot : classes.root}>
        <div className={classes.filePreview}>
          <input
            type="file"
            onChange={this.handleImageChange}
            ref="fileInput"
            accept="image/*"
            style={{
              display: "none",
            }}
          />

          <img
            className={isSmall ? classes.formImageScreen : classes.imageScreen}
            src={this.state.imagePreviewUrl}
            alt="..."
          />
        </div>

        {!disabled && (
          <div className={classes.fileInfo}>
            <div className={classes.Info}>
              <Typography variant="subtitle2" className={classes.errorText}>
                {this.state.imageError || imageError}
              </Typography>

              <Typography
                variant="subtitle1"
                className={
                  this.state.file && this.state.file.name
                    ? classes.uploadedImageFileName
                    : classes.infoText
                }
              >
                {this.state.file && this.state.file.name
                  ? this.state.file.name.toLowerCase().split(/\s/).join("")
                  : "Choose An Image"}
              </Typography>
              <Typography
                variant="caption"
                className={isSmall ? classes.doNotShow : classes.infoText}
              >
                The Image size can not exceed <u>2mbs</u> .
                <br />
                Supported Image formats: <u>{`${imageTypes}`}</u>,
              </Typography>
            </div>

            <div className={classes.actionButtons}>
              {this.state.file === null &&
              !this.state.imagePreviewUrl.includes("inyarwanda") ? (
                <Button {...addButtonProps} onClick={() => this.handleClick()}>
                  {"Select image"}
                </Button>
              ) : (
                <span>
                  <>
                    <Button
                      {...changeButtonProps}
                      onClick={() => this.handleClick()}
                      disabled={isUploading || removing}
                    >
                      Change
                    </Button>

                    <Button
                      {...changeButtonProps}
                      color="success"
                      onClick={() => this.handleSubmit()}
                      disabled={isUploading || removing}
                    >
                      {isUploading ? (
                        <PulseLoader
                          size={5}
                          margin={3}
                          color={"#fff"}
                          loading={isUploading}
                        />
                      ) : (
                        "Upload"
                      )}
                    </Button>
                  </>
                </span>
              )}
            </div>
          </div>
        )}
        <br />
      </div>
    );
  }
}

ImageUpload.propTypes = {
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

export default withStyles(uploadStyle)(ImageUpload);
