import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import AddContestant from "./AddContestant";
import EditContestant from "./EditContestant";
import ContestantsList from "./ContestantsList";
import { folderDate } from "../../../../helpers/helperMethods";
import { uploadToStorageService } from "../../../../services/uploadService";
import Toastify from "../../../../components/MessageToast";

export default ({
  event,
  onAddContestant,
  apiCall,
  onRemoveContestant,
  editContestant,
}) => {
  const [state, setState] = useState({
    component: "view",
    imageUpload: {
      loading: false,
    },
    selectedCandidate: {},
  });

  const onChangeComponentClick = (action, candiate) => {
    setState({
      ...state,
      component: action,
      selectedCandidate: candiate,
    });
  };

  const onUploadImage = async (file) => {
    setState({
      ...state,
      imageUpload: {
        loading: true,
      },
    });
    const destination = `public/images/${event.name
      .replace(/\s/g, "")
      .substring(0, 6)}/${folderDate}`;

    const fd = new FormData();
    fd.append("image", file);
    fd.append("destination", destination);
    const upload = await uploadToStorageService("/images/upload", fd);

    setState({
      ...state,
      imageUpload: {
        loading: false,
        photo: (upload.response && upload.response.fullUrl) || "",
      },
    });

    Toastify({
      message: upload.error
        ? "Failed uploading image, please try again"
        : "Image uploaded successfully",
      type: upload.error ? "error" : "success",
      duration: 4000,
    });
  };

  const onCreateContestant = async (contestantData) => {
    await onAddContestant({
      ...contestantData,
      photo: state.imageUpload.photo,
    });
    setState({ ...state, component: "view" });
  };

  const onEditContestant = async (contestantData) => {
    if (
      contestantData.contestantCode === state.selectedCandidate.contestantCode
    ) {
      contestantData.contestantCode = undefined;
    }
    await editContestant({
      ...contestantData,
      photo: state.imageUpload.photo || state.selectedCandidate.photo,
    });
    setState({ ...state, component: "view" });
  };

  return (
    <Box>
      {state.component === "add" ? (
        <AddContestant
          onChangeComponentClick={onChangeComponentClick}
          onCreateContestant={onCreateContestant}
          onUploadImage={onUploadImage}
          uploadState={state.imageUpload}
          apiCall={apiCall}
          eventCategories={event.categories}
        />
      ) : state.component === "edit" ? (
        <EditContestant
          onChangeComponentClick={onChangeComponentClick}
          onEditContestant={onEditContestant}
          onUploadImage={onUploadImage}
          uploadState={state.imageUpload}
          apiCall={apiCall}
          eventCategories={event.categories}
          candidate={state.selectedCandidate}
        />
      ) : (
        <ContestantsList
          contestants={event.candidates || []}
          onChangeComponentClick={onChangeComponentClick}
          onRemoveContestant={onRemoveContestant}
          onEditContestant={onEditContestant}
        />
      )}
    </Box>
  );
};
