import React from "react";
import { Pie } from "react-chartjs-2";
import { Grid, Typography } from "@material-ui/core";
import HeaderSelect from "../../../components/CustomSelect/HeaderSelect";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/styles/components/chartStyle";

const useStyles = makeStyles(styles);

export default ({
  data,
  title,
  dimensions,
  onDimensionChange,
  defaultDimension,
  width,
}) => {
  const classes = useStyles();

  return (
    <div style={{ width: width || "100%", padding: 10 }}>
      <div className={classes.headContainer}>
        <Typography className={classes.title}> {title} </Typography>
        <span className={classes.dimensionSelector}>
          {dimensions && (
            <HeaderSelect
              onChangeMenu={(selected) => onDimensionChange(selected)}
              value={defaultDimension}
              headerMenus={dimensions}
            />
          )}
        </span>
      </div>

      <div className={classes.chartContainer}>
        <Pie data={data} />
      </div>
    </div>
  );
};
