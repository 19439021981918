// @material-ui/icons
import React from "react";
import DashboardIcon from "@material-ui/icons/Home";
import AccountsIcon from "@material-ui/icons/AccountCircle";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Settings from "@material-ui/icons/Settings";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import FiberSmartRecordIcon from "@material-ui/icons/FiberSmartRecord";
import PermMediaIcon from "@material-ui/icons/PermMedia";

const { claims } = JSON.parse(localStorage.getItem("au")) || {};

var dashRoutes = [
  {
    path: "/",
    name: "Home",
    icon: DashboardIcon,
    page: "home",
    roles: ["admin", "owner", "Ad Creative Editor"],
    accessLevel: "org",
  },
  {
    collapse: true,
    name: "Events",
    customName: "Events",
    icon: EventAvailableIcon,
    state: "eventsCollapse",
    page: "events",
    roles: ["admin", "owner", "Ad Creative Editor"],
    accessLevel: "org",
    views: [
      {
        path: "/events",
        name: "Published events",
        mini: "AE",
        page: "events",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
      {
        path: "/events/pending",
        name: "Pending events",
        mini: "PE",
        page: "pendingEvents",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
      {
        path: "/events/new",
        name: "Create event",
        mini: "CE",
        page: "createEvents",
        roles: ["admin", "owner", "Ad Creative Editor"],
        accessLevel: "org",
      },
    ],
  },
  {
    path: "/orgs",
    name: "Organizations",
    icon: AssignmentTurnedInIcon,
    page: "orgs",
    roles: ["admin", "owner"],
  },

  {
    path: `/orgs/${claims && claims.userOrg && claims.userOrg.orgId}`,
    name: "Account",
    icon: AccountsIcon,
    page: "orgs",
    roles: ["admin", "owner"],
    accessLevel: "org",
    hidden: !claims || !claims.userOrg || !claims.userOrg.orgId,
  },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: Settings,
  //   page: "settings",
  //   roles: ["admin", "owner"],
  // },
];

export default dashRoutes;
