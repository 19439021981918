import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "../views/pages/Dashboard";
import componentAllowed from "../helpers/authorization/componentAllowed";
import NotAllowedLayout from "../layouts/NoAllowedFB";
import ClientsPage from "../views/pages/ClientsPage";
import SettingsPage from "../views/pages/SettingsPage";
import TrafficPage from "../views/pages/performance/Traffic";
import TechnologyPage from "../views/pages/performance/Technology";
import AudiencePage from "../views/pages/performance/Audience";
import ProfilePage from "../views/pages/profile/ProfilePage";
import EditOrganizationPage from "../views/pages/profile/editOrganizationPage";
import EventsPage from "../views/pages/events/EventsPage";
import CreateEventPage from "../views/pages/events/CreateEventPage";
import EditEventPage from "../views/pages/events/EditEventPage";
import MediaCreatives from "../views/pages/MediaCreativesPage";

const ProtectedRoute = ({
  component: Component,
  roles,
  accessLevel,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        componentAllowed(roles, accessLevel) ? (
          <Component {...props} />
        ) : (
          <NotAllowedLayout />
        )
      }
    />
  );
};

const AppRoute = () => {
  return (
    <BrowserRouter>
      <div className="app">
        <Switch>
          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/"
            component={Dashboard}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin"]}
            exact
            path="/orgs"
            component={ClientsPage}
          />
          <ProtectedRoute
            roles={["owner", "admin"]}
            exact
            path="/orgs/:clientId"
            component={ClientsPage}
            accessLevel="org"
          />
          <ProtectedRoute
            roles={["owner", "admin"]}
            exact
            path="/settings"
            component={SettingsPage}
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/events"
            component={EventsPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/events/new"
            component={CreateEventPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/events/pending"
            component={EventsPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/events/:eventId/edit"
            component={EditEventPage}
            accessLevel="org"
          />

          <ProtectedRoute
            roles={["owner", "admin", "Ad Creative Editor"]}
            exact
            path="/events/:eventId"
            component={EventsPage}
            accessLevel="org"
          />

          <ProtectedRoute exact path="/profile" component={ProfilePage} />
          <ProtectedRoute
            exact
            path="/organization/edit/:id"
            component={EditOrganizationPage}
            accessLevel="org"
          />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default AppRoute;
