import React from "react";
import { Typography, Divider } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import priceCardStyle from "../../../../assets/styles/components/priceCard.style";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {
  formatNumber,
  useWindowDimensions,
} from "../../../../helpers/helperMethods";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const PriceCard = ({ plan, onPlanChoose, classes, selectedPlan }) => {
  const { planType, price, features } = plan;

  const handleClick = () => {
    onPlanChoose(plan);
  };
  const { width } = useWindowDimensions();
  return (
    <div
      className={
        selectedPlan.price === plan.price ? classes.selectedRoot : classes.root
      }
    >
      <div className={classes.titleContainer} onClick={handleClick}>
        <Typography className={classes.price}>
          Rwf {formatNumber(price)}
        </Typography>
        <div className={classes.features}>
          <Typography className={classes.title}>{planType}</Typography>
          {features.map((feature, index) => (
            <div key={feature}>
              <Typography className={classes.feature} variant="subtitle1">
                <PlayArrowIcon className={classes.icon} /> {feature}
              </Typography>
              {features.length - 1 !== index && <Divider />}
            </div>
          ))}
        </div>
        {width > 600 && (
          <div>
            {selectedPlan.price === plan.price ? (
              <CheckCircleIcon />
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(priceCardStyle)(PriceCard);
