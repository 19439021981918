import React from 'react';
import AppLayout from '../../layouts/AppLayout';
import AppUsersContainer from '../containers/settings/AppSettingsContainer';

export default () => {
  return  (
    <AppLayout>
      <AppUsersContainer />
    </AppLayout>
  );;
};
