import React from "react";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { dangerColor } from "../../../assets/styles/base";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "30px 0",
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  groupInputElt: {
    padding: "10px 0",
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(2,1fr)",
    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1,1fr)",
      padding: "5px 0",
    },
  },
  selectTitle: {
    color: "grey",
  },
  submitBtn: {
    padding: "15px 0",
    width: "50%",
    backgroundColor: "#1876D1",
    textAlign: "center",

    "&:hover": {
      background: "#1876D1",
      cursor: "pointer",
    },
    "@media (max-width: 600px)": {
      padding: "10px 0",
      width: "100%",
    },
  },
  errorMsg: {
    color: "red",
  },
  otpMessage: {
    color: dangerColor[0],
    textAlign: "center",
    fontSize: "18px",
  },
}));

export default ({
  onInputChange,
  onPhoneChange,
  content,
  formError,
  disabled,
  showOtp,
  otpData,
  paymentOptions,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!showOtp || !content.phone ? (
        <>
          {paymentOptions.price ? (
            <div className={classes.inputElt}>
              <Typography variant="h6">
                Plan Type: {paymentOptions.planType}
              </Typography>
              <Typography variant="subtitle1">
                Amount: {paymentOptions.price} Rwf
              </Typography>
            </div>
          ) : (
            <div className={classes.inputElt}>
              <FormControl fullWidth>
                <InputLabel id="amount-label">Votes</InputLabel>
                <Select
                  labelId="amount-label"
                  id="amount"
                  value={content.amount}
                  label="Votes"
                  name="amount"
                  onChange={onInputChange}
                  placeholder="Enter Votes"
                  disabled={disabled}
                >
                  <MenuItem value={0}>
                    <span className={classes.selectTitle}>Choose Votes</span>
                  </MenuItem>
                  {paymentOptions.options.map((elt) => (
                    <MenuItem key={elt} value={elt.amount}>
                      {elt.amount} Rwf: {elt.votes} Votes
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <br />
          <PhoneInput
            country={"rw"}
            className={classes.inputElt}
            value={content.phone}
            onChange={onPhoneChange}
            inputStyle={{ width: "100%" }}
            disabled={disabled}
          />
          <Typography className={classes.errorMsg} variant="subtitle1">
            {formError}
          </Typography>
        </>
      ) : (
        <div className={classes.inputElt}>
          <Typography variant="subtitle1">
            Please enter the MOMO validation OTP sent to you via SMS and
            Whatsapp to complete this transaction.
          </Typography>
          <br />
          <TextField
            id="otp"
            label="OTP"
            placeholder="Enter your OTP"
            onChange={onInputChange}
            value={content.otp}
          />
          <br />
          {otpData && (
            <Typography variant="body2" className={classes.otpMessage}>
              {otpData.message}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};
