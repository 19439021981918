import { GET_APP_INFO, GET_APP_PENDING_INVITE } from './types';

const initialState = {
  loading: false,
  data: {},
};

export default class SettingsReducers {
  static appInfoReducer(state = initialState, action) {
    switch (action.type) {
      case `${GET_APP_INFO}_PENDING`:
        return { ...state, loading: true };
      case `${GET_APP_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_APP_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static appPendingInvitesReducer(state = initialState, action) {
    switch (action.type) {
      case `${GET_APP_PENDING_INVITE}_PENDING`:
        return { ...state, loading: true };
      case `${GET_APP_PENDING_INVITE}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_APP_PENDING_INVITE}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
