import React, { useEffect, useState } from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router";
import NoContent from "../../../components/NoContent";
import EnhancedTableHead from "../../../components/Table/tableHead";
import Skeleton from "@material-ui/lab/Skeleton";
import { primaryColor, whiteColor } from "../../../assets/styles/base";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    padding: 10,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  doNotShowInMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  titleSkeleton: {
    width: 400,
    height: 40,
    float: "left",
    [theme.breakpoints.down("xs")]: {
      width: 100,
    },
  },
  skeleton: {
    width: "100%",
    height: 40,
    float: "right",
  },
  paginationSkeleton: {
    width: "100%",
    height: 30,
    marginLeft: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  isClickable: {
    cursor: "pointer",
  },
  title: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
  },
}));

export default (props) => {
  const {
    isUpdating,
    headCells,
    rows,
    title,
    generalUrl,
    hasLongTitles,
    noContentCustomElements,
  } = props;

  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isInMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [page, setPage] = useState(0);
  const [displayResult, setDisplayResult] = useState({
    startIndex: 0,
    endIndex: 10,
    result: [],
    count: 0,
  });

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("post-date");

  useEffect(() => {
    setDisplayResult({
      ...displayResult,
    });
  }, [isUpdating]);

  useEffect(() => {
    const rowNamesData = stableSort(rows, getComparator(order, orderBy)).filter(
      (rowName) =>
        rowName.column1.content1
          ? rowName.column1.content2
            ? rowName.column1.content2.toLowerCase()
            : ""
          : rowName.column1.name || rowName.column1.toLowerCase()
    );

    setDisplayResult({
      ...displayResult,
      result: rowNamesData,
      count: rowNamesData.length,
      searchTerm: isUpdating ? "" : displayResult.searchTerm,
    });
  }, [
    displayResult.startIndex,
    rows.length,
    orderBy,
    order,
    displayResult.searchTerm,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setDisplayResult((prevState) => ({
      ...prevState,
      startIndex:
        page < newPage ? prevState.startIndex + 10 : prevState.startIndex - 10,
      endIndex:
        page < newPage ? prevState.endIndex + 10 : prevState.endIndex - 10,
    }));

    setPage(newPage);
  };

  const goToPage = (id) =>
    history.push(generalUrl ? `${generalUrl}/${id}` : `/campaign/${id}`);

  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Typography className={classes.title}>{title}</Typography>
        <Table
          className={classes.table}
          aria-label="customized table"
          size={isInMobile ? "small" : "medium"}
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />

          <TableBody>
            {displayResult.result[0] ? (
              displayResult.result.map((row) => (
                <StyledTableRow key={row.id}>
                  {headCells.map((elt, index) => (
                    <>
                      {index === 0 ? (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          key={index}
                          className={classes.isClickable}
                          onClick={() => {
                            if (row.column1.url) {
                              history.push(row.column1.url);
                            } else {
                              goToPage(row.id);
                            }
                          }}
                        >
                          {isUpdating ? (
                            <Skeleton
                              variant="rect"
                              className={classes.titleSkeleton}
                            />
                          ) : (
                            <Grid
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                            >
                              {row.column1.content1 ? (
                                <>
                                  <Grid item> {row.column1.content1}</Grid>

                                  <Grid item className={classes.titleText}>
                                    <span>
                                      {hasLongTitles
                                        ? `${row.column1.content2.substring(
                                            0,
                                            40
                                          )}...`
                                        : row.column1.content2}
                                    </span>
                                  </Grid>
                                </>
                              ) : row.column1.name ? (
                                <Grid item className={classes.titleText}>
                                  <span>
                                    {hasLongTitles
                                      ? `${row.column1.name.substring(
                                          0,
                                          35
                                        )}...`
                                      : row.column1.name}
                                  </span>
                                </Grid>
                              ) : (
                                <Grid item className={classes.titleText}>
                                  <span>
                                    {hasLongTitles
                                      ? `${row.column1.substring(0, 35)}...`
                                      : row.column1}
                                  </span>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          align="right"
                          className={
                            elt.doNotShowInMobile
                              ? classes.doNotShowInMobile
                              : ""
                          }
                          key={index}
                        >
                          {isUpdating ? (
                            <Skeleton
                              variant="rect"
                              className={classes.skeleton}
                            />
                          ) : (
                            <span>{row[`column${index + 1}`]}</span>
                          )}
                        </StyledTableCell>
                      )}
                    </>
                  ))}
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                {headCells.map((cell, index) => (
                  <StyledTableCell align="right">
                    {Math.round(headCells.length / 3) === index && (
                      <NoContent
                        title="No Content"
                        customElements={noContentCustomElements || null}
                      />
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
