import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import { useHistory } from "react-router-dom";
import defaultImg from "../../../assets/images/placeholder.png";
import itemInfoStyles from "../../../assets/styles/components/itemInfoStyles";
import SingleItemMenu from "../../../components/Menu/SingleItemMenu";
import { Edit } from "@material-ui/icons";
import { dangerColor, successColor } from "../../../assets/styles/base";
import Chip from "@material-ui/core/Chip";
import { formatNumber } from "../../../helpers/helperMethods";

const styles = makeStyles((theme) => itemInfoStyles(theme));

export default (props) => {
  const { content } = props;

  const history = useHistory();
  const classes = styles();

  const actions = [
    {
      onClick: () => history.push(`/media-creative/${content.id}/edit`),
      text: "Edit Creative",
      icon: <Edit fontSize="small" />,
    },
  ];

  return (
    <div>
      <Grid container className={classes.topSection}>
        <Grid item xs={12} container justify="flex-start">
          <div>
            <ProgressiveImage
              src={"content.imgUrl"}
              placeholder={
                props.placeholder ||
                `https://via.placeholder.com/500x450.png?text=${content.name}`
              }
            >
              {(src, loading) => (
                <img
                  style={{
                    opacity: loading ? 0.7 : 1,
                    objectFit: "contain",
                    borderRadius: "6px",
                    maxHeight: "50vh",
                    marginRight: "10px",
                  }}
                  src={src}
                  alt="No cover"
                  className={classes.image}
                />
              )}
            </ProgressiveImage>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="column"
          spacing={2}
          className={classes.infoSection}
        >
          <Grid item className={classes.textInfo}>
            <Typography variant="h4" className={classes.texts}>
              {content.name}
            </Typography>

            <Grid item className={classes.actions}>
              <Grid container spacing={2} justify="flex-end">
                <SingleItemMenu horiz background menu={actions} />
              </Grid>
            </Grid>

            <Tooltip title="Advertisement Type" placement="top-start" arrow>
              <Typography variant="h6" className={classes.name}>
                {content.type || "Unknown"}
              </Typography>
            </Tooltip>
            {content.adContent}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
