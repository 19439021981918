import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import itemInfoStyles from "../../../assets/styles/components/itemInfoStyles";
import SingleItemMenu from "../../../components/Menu/SingleItemMenu";
import { Edit, Launch, Report as ReportIcon } from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import { successColor } from "../../../assets/styles/base";
import { TabSelector } from "../../../components/Tabs/TabsSelector";
import { useTabs, TabPanel } from "react-headless-tabs";
import { formatDate } from "../../../helpers/helperMethods";
import fullUrlService from "../../../services/fullUrlService";
import Toast from "../../../components/MessageToast";

const styles = makeStyles((theme) => itemInfoStyles(theme));
const PaymentTable = ({ payment }) => {
  const classes = styles();

  const [selectedTab, setSelectedTab] = useTabs([
    "RWF",
    ...(payment?.cardOptions.currency || []),
  ]);
  const onChange = (text) => setSelectedTab(text);

  const currencies = payment?.momoOptions
    ? ["RWF", ...payment.cardOptions.currency.filter((elt) => elt != "RWF")]
    : payment?.cardOptions.currency || [];
  return (
    <div className={classes.payment}>
      <div className="tabs-nav">
        {currencies.map((elt) => (
          <TabSelector
            key={elt}
            isActive={selectedTab === elt}
            onClick={() => onChange(elt)}
          >
            {elt}
          </TabSelector>
        ))}
      </div>
      {currencies.map((currency) => (
        <TabPanel
          key={currency}
          hidden={selectedTab !== currency}
          style={{ padding: "10px" }}
        >
          {payment.cardOptions ? (
            <div>
              {currency === "RWF" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                    }}
                  >
                    <Typography> Mobile Money </Typography>
                    {payment.momoOptions.voteOptions.map((opt) => (
                      <Typography variant="caption" key={opt.amount}>
                        {`${opt.amount} RWF For ${opt.votes} Vote(s)`} <br />
                      </Typography>
                    ))}
                  </div>
                  <div>
                    <Typography> Cards </Typography>
                    {payment.cardOptions.RWF.voteOptions.map((opt) => (
                      <Typography variant="caption" key={opt.amount}>
                        {`${opt.amount} RWF For ${opt.votes} Vote(s)`} <br />
                      </Typography>
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  {payment.cardOptions[`${currency}`].voteOptions.map((opt) => (
                    <Typography variant="caption" key={opt.amount}>
                      {`${opt.amount} ${currency}  For ${opt.votes} Vote(s)`}
                      <br />
                    </Typography>
                  ))}
                </div>
              )}
            </div>
          ) : null}
        </TabPanel>
      ))}
    </div>
  );
};

const TicketsTable = ({ payment }) => {
  const classes = styles();

  const [selectedTab, setSelectedTab] = useTabs([
    ...(payment?.ticketsOptions.map((tier) => tier.name) || []),
  ]);
  const onChange = (text) => setSelectedTab(text);

  const currencies = payment?.ticketsOptions
    ? [
        ...payment.ticketsOptions
          .map((tier) => tier.name)
          .filter((elt) => elt.name != "Regular"),
      ]
    : payment?.ticketsOptions.map((tier) => tier.name) || [];

  return (
    <div className={classes.payment}>
      <div className="tabs-nav">
        {currencies.map((elt) => (
          <TabSelector
            key={elt}
            isActive={selectedTab === elt}
            onClick={() => onChange(elt)}
          >
            {elt}
          </TabSelector>
        ))}
      </div>
      {currencies.map((currency) => {
        return (
          <TabPanel
            key={currency}
            hidden={selectedTab !== currency}
            style={{ padding: "10px" }}
          >
            {payment.ticketsOptions ? (
              <div>
                {payment.ticketsOptions.map(
                  (opt) =>
                    opt.name === currency && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "10px",
                        }}
                      >
                        <Typography variant="caption" key={opt.name}>
                          {`${opt.totalPlaces - opt.remainingPlaces} / ${
                            opt.totalPlaces
                          } sold Ticket(s)`}
                          <br />
                        </Typography>
                        {opt.totalPlaces - opt.remainingPlaces !==
                        opt.totalPlaces ? (
                          <Chip
                            style={{ marginLeft: "20px" }}
                            color="primary"
                            variant="outlined"
                            size="small"
                            label="Tickets Avairable"
                          />
                        ) : (
                          <Chip
                            style={{ marginLeft: "20px" }}
                            color="secondary"
                            variant="outlined"
                            size="small"
                            label="Sold Out"
                          />
                        )}
                      </div>
                    )
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {payment.ticketsOptions
                    .map((tier) => tier.name === currency && tier)
                    .filter((item) => item !== false)
                    .map((tier) => ({
                      ...tier,
                      prices: tier.prices.reduce(
                        (a, b) => ({ ...a, [b.currency]: b }),
                        {}
                      ),
                    }))
                    .map((opt) => (
                      <>
                        {opt.prices.RWF && (
                          <div
                            style={{
                              marginRight: "20px",
                            }}
                          >
                            <Typography>
                              {opt.prices.RWF?.currency
                                ? opt.prices.RWF?.currency
                                : "RWF"}
                            </Typography>

                            <Typography
                              variant="caption"
                              key={opt.prices.RWF?.currency}
                            >
                              {`${
                                opt.prices.RWF?.amount
                                  ? opt.prices.RWF?.amount
                                  : 0
                              } ${
                                opt.prices.RWF?.currency
                                  ? opt.prices.RWF?.currency
                                  : "RWF"
                              } For 1 Ticket(s)`}{" "}
                              <br />
                            </Typography>
                          </div>
                        )}
                        {opt.prices.USD && (
                          <div
                            style={{
                              marginRight: "20px",
                            }}
                          >
                            <Typography>{opt.prices.USD.currency}</Typography>

                            <Typography
                              variant="caption"
                              key={opt.prices.USD.currency}
                            >
                              {`${opt.prices.USD.amount} ${opt.prices.USD.currency} For 1 Ticket(s)`}{" "}
                              <br />
                            </Typography>
                          </div>
                        )}
                        {opt.prices.EUR && (
                          <div
                            style={{
                              marginRight: "20px",
                            }}
                          >
                            <Typography>{opt.prices.EUR.currency}</Typography>

                            <Typography
                              variant="caption"
                              key={opt.prices.EUR.currency}
                            >
                              {`${opt.prices.EUR.amount} ${opt.prices.EUR.currency} For 1 Ticket(s)`}{" "}
                              <br />
                            </Typography>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            ) : null}
          </TabPanel>
        );
      })}
    </div>
  );
};

export default (props) => {
  const { eventData, handleLaunchCampaign, user, onActivateOrStopEvent } =
    props;

  const history = useHistory();
  const classes = styles();

  const actions = [
    {
      onClick: () => history.push(`/events/${eventData.id}/edit`),
      text: "Edit Event",
      icon: <Edit fontSize="small" />,
    },
    {
      icon: eventData.isActive ? (
        <Launch fontSize="small" />
      ) : (
        <Launch fontSize="small" />
      ),
      text: eventData.isActive ? "Stop event" : "Activate event",
      onClick: () => onActivateOrStopEvent(eventData.id, eventData.isActive),
      hidden:
        eventData.category !== "Voting" || eventData.status !== "published",
    },
    {
      text: "Generate Report",
      icon: <ReportIcon fontSize="small" />,
      onClick: async () => {
        Toast({
          message:
            "We are generating your report, we will notify you once done.",
          type: "warning",
          duration: 8000,
        });
        const response = await fullUrlService({
          path: `${process.env.VOTING_REPORT_API}?eventId=${eventData.id}&email=${user.email}`,
          method: "GET",
        });
        if (response.error) {
          Toast({
            message: response.message,
            type: "error",
            duration: 5000,
          });
        } else {
          Toast({
            message: response.message,
            type: "success",
            duration: 5000,
          });
          const link = document.createElement("a");
          link.href = response.link;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },
    },
  ];

  const [state, setState] = useState({
    showPaymentDetails: false,
    showTicketsTiers: false,
  });

  const showPaymentDetails = () => {
    setState((prevState) => ({
      ...prevState,
      showPaymentDetails: !prevState.showPaymentDetails,
    }));
  };
  const showTicketsTiers = () => {
    setState((prevState) => ({
      ...prevState,
      showTicketsTiers: !prevState.showTicketsTiers,
    }));
  };
  return (
    <div>
      <Grid container className={classes.topSection}>
        <Grid item xs={12} container justify="flex-start">
          <Grid item className={classes.textInfo}>
            <Typography variant="h4" className={classes.texts}>
              {eventData.name}
            </Typography>
            <br />

            <Tooltip title="Campaign Period" placement="top-start" arrow>
              <Typography variant="h6" className={classes.name}>
                {formatDate(eventData.startDate)} -{" "}
                {formatDate(eventData.endDate)}
              </Typography>
            </Tooltip>
            <Typography variant="h6" className={classes.texts}>
              <Chip
                label={eventData.status}
                style={{
                  backgroundColor: `${successColor[0]}`,
                  color: "#fff",
                  size: "100px",
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="column"
          spacing={2}
          className={classes.infoSection}
        >
          <Grid item className={classes.textInfo}>
            <Grid item className={classes.actions}>
              <Grid container spacing={2} justify="flex-end">
                <SingleItemMenu horiz background menu={actions} />
              </Grid>
              <Grid container spacing={2} justify="flex-end">
                <a
                  href={`https://events.noneho.com/${eventData.id}`}
                  target="_blank"
                  style={{
                    paddingTop: 20,
                    textDecoration: "none",
                    color: "#044368",
                  }}
                >
                  Preview online
                </a>
              </Grid>
            </Grid>

            <Typography variant="body1" className={classes.texts}>
              Category: {eventData.category || "Unset"}
            </Typography>

            {eventData.payment?.required && (
              <Typography variant="body1" className={classes.texts}>
                Payment: {eventData.payment?.required ? "Required" : "Not set"}
                <br />
                {eventData.category !== "Concert" && (
                  <Typography
                    className={classes.paymentDetails}
                    variant="subtitle2"
                    onClick={showPaymentDetails}
                  >
                    {`${state.showPaymentDetails ? "Hide" : "Show"}`} Payment
                  </Typography>
                )}
                {eventData.category === "Concert" && (
                  <Typography
                    className={classes.paymentDetails}
                    variant="subtitle2"
                    onClick={showTicketsTiers}
                  >
                    {`${state.showTicketsTiers ? "Hide" : "Show"}`} Tickets
                    Options
                  </Typography>
                )}
                {state.showPaymentDetails && (
                  <PaymentTable payment={eventData.payment} />
                )}
                {state.showTicketsTiers && (
                  <TicketsTable payment={eventData.payment} />
                )}
              </Typography>
            )}

            <Typography variant="body1" className={classes.texts}>
              Venue: {eventData.venue}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
