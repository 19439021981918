export default (roles, accessLevel = 'app') => {
  const currentUser = JSON.parse(localStorage.getItem('au'));
  if (!currentUser) return false;
  const { claims } = currentUser;
  if (claims.superAdmin) return true;
  const { userApp, userOrg } = claims;
  if (userApp.role === 'owner') return true;
  if (accessLevel === 'app' && roles.findIndex((r) => r === userApp.role) > -1)
    return true;
  if (
    accessLevel === 'org' &&
    (roles.findIndex((r) => r === userOrg.role) > -1 ||
      userApp.role === 'admin')
  )
    return true;

  return false;
};
