import React from "react";
import NewDashboard from "./NewDashboard";
import Dashboard from "./Dashboard";

export default () => {
  const userString = localStorage.getItem("au");
  const user = JSON.parse(userString) || {};

  return <NewDashboard user={user} />;
};
