import {
  FETCH_ALL_CAMPAIGNS_INFO,
  FETCH_SINGLE_CAMPAIGN_INFO,
  FETCH_CAMPAIGN_COUNTRY_INFO,
  FETCH_CAMPAIGN_DEMOGRAPHICS_INFO,
  CREATE_CAMPAIGN,
  FETCH_PENDING_CAMPAIGNS,
} from "./types";

const initialState = {
  loading: false,
  data: {},
};

export default class CampaignReducers {
  static allCampaignsReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_ALL_CAMPAIGNS_INFO}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_ALL_CAMPAIGNS_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_ALL_CAMPAIGNS_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static singleCampaignReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_SINGLE_CAMPAIGN_INFO}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_SINGLE_CAMPAIGN_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_SINGLE_CAMPAIGN_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static campaignsCountryInfoReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_CAMPAIGN_COUNTRY_INFO}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_CAMPAIGN_COUNTRY_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_CAMPAIGN_COUNTRY_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static campaignsDemographicsInfoReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_CAMPAIGN_DEMOGRAPHICS_INFO}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_CAMPAIGN_DEMOGRAPHICS_INFO}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_CAMPAIGN_DEMOGRAPHICS_INFO}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static createCampaignReducer(state = initialState, action) {
    switch (action.type) {
      case `${CREATE_CAMPAIGN}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${CREATE_CAMPAIGN}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${CREATE_CAMPAIGN}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static pendingCampaignsReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_PENDING_CAMPAIGNS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_PENDING_CAMPAIGNS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_PENDING_CAMPAIGNS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
