import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/isLoading";
import RecordsComponent from "../../components/dashboard/RecordsComponent";
import TimePicker from "../../components/date/TimePickerComponent";
import moment from "moment";
import ChartContainer from "./ChartContainer";
import reduxAction from "../../../redux/action";
import { FETCH_PUBLISHER } from "../../../redux/publishers/types";
import MapsContainer from "../Maps/Map";
import DemographicsContainer from "./DemographicsChartsContainer";
import TechnologyContainer from "./TechnologyContainer";
import {
  handleDates,
  useWindowDimensions,
} from "../../../helpers/helperMethods";
import { Icon } from "@iconify/react";
import webIcon from "@iconify-icons/mdi/web";
import bxlFacebook from "@iconify-icons/bx/bxl-facebook";
import instagramFill from "@iconify-icons/akar-icons/instagram-fill";
import ViewMore from "../../../components/ViewMore";

export default ({ user }) => {
  const dispatch = useDispatch();
  const singlePublisherStore = useSelector((store) => store.singlePublisher);
  const selectedPublishersStore = useSelector((state) => state.selectPublisher);

  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });

  const userInfo = {
    email: user.email,
    role: user.claims.userOrg.role,
  };

  const { startDate, endDate } = handleDates(state);

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/publishers/${selectedPublishersStore.selectedPublisher}/data?startDate=${startDate}&endDate=${endDate}`,
        actionType: FETCH_PUBLISHER,
      })
    );
  }, [selectedPublishersStore.selectedPublisher]);

  const onRangeChange = (range) => setState({ [range]: !state[range] });
  const onDateChange = (date) => setState({ ...date });

  useEffect(() => {
    if (
      !singlePublisherStore.data.results ||
      (state.date && state.date.startDate) ||
      state.date !== moment().format("YYYY-MM-DD") ||
      state.daily ||
      state.weekly ||
      state.monthly ||
      state.yearly
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/publishers/${selectedPublishersStore.selectedPublisher}/data?startDate=${startDate}&endDate=${endDate}`,
          actionType: FETCH_PUBLISHER,
        })
      );
    }
  }, [state]);

  const recordsToDisplay = [
    {
      title: "Inyarwanda.com",
      color: "warning",
      icon: <Icon icon={webIcon} />,
      value:
        singlePublisherStore.data.results &&
        singlePublisherStore.data.results.website.totalViews,
    },
    {
      title: "Facebook",
      color: "warning",
      icon: <Icon icon={bxlFacebook} />,
      value:
        singlePublisherStore.data.results &&
        singlePublisherStore.data.results.facebook.page_impressions,
    },
    {
      title: "Instagram",
      color: "warning",
      icon: <Icon icon={instagramFill} />,
      value:
        singlePublisherStore.data.results &&
        singlePublisherStore.data.results.instagram.impressions,
    },
  ];

  const chartDimensions = recordsToDisplay.map((record) => ({
    id: record.title.toLocaleLowerCase(),
    name: record.title,
  }));

  const { width } = useWindowDimensions();

  return (
    <div>
      {singlePublisherStore.loading && !singlePublisherStore.data.results ? (
        <Loading />
      ) : (
        <>
          <TimePicker
            startDate={startDate}
            endDate={endDate}
            onRangeChange={onRangeChange}
            onDateChange={onDateChange}
            currentDate={moment().format("YYYY-MM-DD")}
            lastSyncTime={
              moment(
                singlePublisherStore.data.results &&
                  singlePublisherStore.data.results.lastSyncTime
              ).format("LLL") || "00:00 AM"
            }
          />

          <RecordsComponent
            role={userInfo.role}
            isUpdating={
              singlePublisherStore.loading && singlePublisherStore.data.results
            }
            records={recordsToDisplay}
          />
          <ChartContainer
            showViewMore
            viewMoreUrl="/traffic"
            chartData={
              singlePublisherStore.data.results
                ? singlePublisherStore.data.results
                : []
            }
            dataTitle="Performance"
            role={userInfo.role}
            isUpdating={
              singlePublisherStore.loading && singlePublisherStore.data.results
            }
            isDateRange={startDate !== endDate}
            dimensions={chartDimensions}
          />

          <MapsContainer
            showViewMore
            viewMoreUrl="/audience"
            selectedPublisher={selectedPublishersStore.selectedPublisher}
            startDate={startDate}
            endDate={endDate}
            dimensions={chartDimensions}
          />

          <DemographicsContainer
            dataTitle="Demographics"
            role={userInfo.role}
            isUpdating={false}
            isDateRange={startDate !== endDate}
            dimensions={chartDimensions}
            selectedPublisher={selectedPublishersStore.selectedPublisher}
            startDate={startDate}
            endDate={endDate}
            chartWidth={width}
          />
          <br />
          <TechnologyContainer
            showViewMore
            viewMoreUrl="/technology"
            dataTitle="Technology"
            role={userInfo.role}
            isUpdating={false}
            isDateRange={startDate !== endDate}
            dimensions={chartDimensions}
            selectedPublisher={selectedPublishersStore.selectedPublisher}
            startDate={startDate}
            endDate={endDate}
            chartWidth={width}
          />
        </>
      )}
    </div>
  );
};
