import { IS_LOADING } from "./types";

const initialState = {
  loading: false,
};

export default class HelperReducers {
  static loadingReducer(state = initialState, action) {
    switch (action.type) {
      case IS_LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      default:
        return state;
    }
  }
}
