import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Card from "@material-ui/core/Card";

import { makeStyles } from "@material-ui/core/styles";

import Button from "../../../../components/Buttons/customButton";
import { primaryColor } from "../../../../assets/styles/base";
import CustomInput from "../../../../components/CustomInput/TextField";
import ImagePlaceholder from "../../../../assets/images/placeholder.png";
import ImageUpload from "../../../../components/CustomUpload/ImageUpload";
import SimpleCustomSelect from "../../../../components/CustomSelect/SimpleCustomSelect";

const useStyles = makeStyles((theme) => ({
  createButton: {
    backgroundColor: primaryColor[0],
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: primaryColor[0],
    },
  },
  coverPhoto: {
    height: theme.spacing(30),
    width: theme.spacing(30),
  },
  card: {
    padding: 10,
  },
}));

export default ({
  onChangeComponentClick,
  onEditContestant,
  onUploadImage,
  uploadState,
  apiCall,
  eventCategories,
  candidate,
}) => {
  const classes = useStyles();
  const [contestant, setContestant] = useState(candidate);
  const [categoryState, setCategoryState] = useState({
    eventCategories:
      eventCategories &&
      eventCategories.map((elt) => ({
        value: elt,
        label: elt,
      })),
    category: candidate.category || "",
  });

  const onInputTextChange = (e) => {
    setContestant({
      ...contestant,
      [e.target.name]: e.target.value,
    });
  };

  const onDetailsInputTextChange = (e) => {
    setContestant({
      ...contestant,
      details: {
        ...contestant.details,
        [e.target.name]: e.target.value,
      },
    });
  };

  const onSubmit = () => {
    onEditContestant(contestant);
  };

  const handleCategoriesChange = (text) => {
    if (!!text || text !== "") {
      setCategoryState({ ...categoryState, category: text });
      setContestant({
        ...contestant,
        category: text && text.label ? text.label : text,
      });
    }
  };

  return (
    <Card className={classes.card}>
      <Typography variant="h6" align="center">
        Edit contestant
      </Typography>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        <ListItem>
          <CustomInput
            label="Contestant name"
            value={contestant.firstName || ""}
            id="firstName"
            name="firstName"
            fullWidth
            onChange={onInputTextChange}
          />
        </ListItem>
        {/* <ListItem>
          <CustomInput
            label='First name'
            value={contestant.firstName || ''}
            id='firstName'
            name='firstName'
            fullWidth
            onChange={onInputTextChange}
          />
        </ListItem>
        <ListItem>
          <CustomInput
            label='Last name'
            value={contestant.lastName || ''}
            id='lastName'
            name='lastName'
            fullWidth
            onChange={onInputTextChange}
          />
        </ListItem> */}
        <ListItem>
          <CustomInput
            label="Contestant Voting Id"
            value={contestant.contestantCode || ""}
            id="contestantCode"
            name="contestantCode"
            fullWidth
            type="number"
            onChange={onInputTextChange}
          />
        </ListItem>
        {eventCategories && (
          <ListItem style={{ display: "block", width: "100%" }}>
            <SimpleCustomSelect
              placeholder="Add Contestant categories"
              value={categoryState.category || ""}
              options={categoryState.eventCategories || []}
              onInputChange={handleCategoriesChange}
              onChange={handleCategoriesChange}
            />
          </ListItem>
        )}
        <Typography variant="subtitle1" style={{ marginLeft: 20 }}>
          Contestant photo
        </Typography>
        <ListItem>
          <ImageUpload
            hasMoreInfo
            isSmall
            defaultImage={contestant.photo || ImagePlaceholder}
            imageSubmit={onUploadImage}
            imageTypes={
              "image/jpeg, image/png, image/jpg, image/gif, image/svg, image/bmp, image/ico"
            }
            addButtonProps={{
              color: "primary",
            }}
            changeButtonProps={{
              color: "primary",
            }}
            removeButtonProps={{
              color: "danger",
            }}
            isUploading={uploadState.loading}
          />
        </ListItem>
        <div
          style={{
            padding: 20,
            margin: 20,
            backgroundColor: "rgba(0,0,0,0.01",
          }}
        >
          <Typography variant="subtitle1">Additional info</Typography>
          <CustomInput
            label="Contestant description"
            value={contestant.description || ""}
            id="description"
            name="description"
            multiline
            rows={2}
            fullWidth
            onChange={onInputTextChange}
          />
          <Typography variant="subtitle1">Social media</Typography>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <CustomInput
                label="Instagram url"
                value={
                  (contestant.details && contestant.details.instagram) || ""
                }
                id="instagram"
                name="instagram"
                fullWidth
                onChange={onDetailsInputTextChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                label="Facebook url"
                value={
                  (contestant.details && contestant.details.facebook) || ""
                }
                id="facebook"
                name="facebook"
                fullWidth
                onChange={onDetailsInputTextChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                label="Twitter url"
                value={(contestant.details && contestant.details.twitter) || ""}
                id="twitter"
                name="twitter"
                fullWidth
                onChange={onDetailsInputTextChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                label="Youtube url"
                value={(contestant.details && contestant.details.youtube) || ""}
                id="youtube"
                name="youtube"
                fullWidth
                onChange={onDetailsInputTextChange}
              />
            </Grid>
          </Grid>
        </div>
      </List>

      <Grid container justify="flex-end">
        <Button
          variant="outlined"
          onClick={() => onChangeComponentClick("view")}
          disabled={uploadState.loading || apiCall.loading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.createButton}
          onClick={onSubmit}
          disabled={uploadState.loading}
        >
          {apiCall.loading ? "Submitting..." : "Submit"}
        </Button>
      </Grid>
    </Card>
  );
};
