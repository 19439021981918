import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/isLoading";
import ContestantsTableContainer from "./voteComponents/ContestantsTableContainer";
import ContestantsChart from "./voteComponents/ContestantsChart";
import ConcertCharts from "./concertsComponents/ConcertCharts";
import RecordsContainer from "./voteComponents/RecordsContainer";
import ConcertContainer from "./concertsComponents/ConcertContainer";
import ConcertTableContainer from "./concertsComponents/ConcertTableContainer"
import reduxAction, { fullUrlAction } from "../../../../redux/action";
import {
  FETCH_EVENT_STATISTICS,
  FETCH_SINGLE_EVENT_INFO,
} from "../../../../redux/events/types";
import TimePicker from "../../../components/date/TimePickerComponent";
import moment from "moment";
import { formatContestantsData } from "../../../../helpers/formatData";
import { handleDates } from "../../../../helpers/helperMethods";
import EventComponent from "../../../components/events/EventComponent";
import apiService from "../../../../services/apiService";
import Toast from "../../../../components/MessageToast";
// import DummyData from "../../../../stats.json"

export default ({ eventId }) => {
  const singleEventStore = useSelector((state) => state.singleEvent);
  const eventStatistics = useSelector((state) => state.eventStatistics);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DD"),
  });
  const [dataState, setDataState] = useState({
    contestantsData: [],
    authorRecords: [],
  });
  const userString = localStorage.getItem("au");
  const user = JSON.parse(userString) || {};
  const userInfo = {
    email: user.email,
    role: user.claims.userOrg.role,
  };

  const data ="ertyui"
  const [eventCategory, setEventCategory] = useState({})

  const { startDate, endDate } = handleDates(state);
   useEffect(async()=>{
      const response = await apiService({
      path: `/events/${eventId}`,
      method: "GET",
      });
      setEventCategory({
        category: response.results.category,
      })
    },[]);

  useEffect(() => {
    if (
      (!singleEventStore.data[0] ||
      (state.date && state.date.startDate) ||
      state.date !== moment().format("YYYY-MM-DD") ||
      state.daily ||
      state.weekly ||
      state.monthly ||
      state.yearly)
    ) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/events/${eventId}?startDate=${startDate}&endDate=${endDate}`,
          actionType: FETCH_SINGLE_EVENT_INFO,
        })
      );
      dispatch(
        fullUrlAction({
          method: "GET",
          path: `${process.env.API_BASE_URL}/events/${eventId}/stats?startDate=${startDate}&endDate=${endDate}`,
          actionType: FETCH_EVENT_STATISTICS,
        })
      );
    }
  }, [state]);

  let contestantsData = [];
 

  useEffect(() => {
    const candidatesSummary = singleEventStore.data.results
      ? singleEventStore.data.results.candidates
      : [];
    
    for (const contestant of candidatesSummary) {
      contestantsData.push(formatContestantsData(contestant));
    }

    setDataState({
      ...dataState,
      contestantsData,
    });
  }, [singleEventStore.data.results]);

  const onRangeChange = (range) => setState({ [range]: !state[range] });
  const onDateChange = (date) => setState({ ...date });

  const changeContestantsForSelectedMenu = (menu, selectedItems) => {
    const selectedItemsContestants = [];
    // Votiing
    if (singleEventStore.data.results?.category === "Voting" && (!selectedItems || selectedItems.length < 1)) {
      setDataState({
        ...dataState,
        contestantsData: eventStatistics.data.results
          ? eventStatistics.data.results.LatestOverallVotes.CandidatesSummary.map(
              (el) => formatContestantsData(el)
            )
          : [],
      });
      return;
    }
    if (menu === "contestants" && singleEventStore.data.results.category === "Voting") {
      const candidatesSummary = eventStatistics.data.results
        ? eventStatistics.data.results.LatestOverallVotes.CandidatesSummary
        : [];
      for (const contestant of candidatesSummary) {
        if (selectedItems.findIndex((i) => i.label === contestant.name) > -1) {
          selectedItemsContestants.push(formatContestantsData(contestant));
        }
      }
    }
    if (menu === "dates" && singleEventStore.data.results.category === "Voting") {
      const datesSummary = singleEventStore.data.results
        ? singleEventStore.data.results.DailyVotes
        : [];

      for (const dateElr of datesSummary) {
        if (dateElr.Date !== "") {
          const date = new Date(dateElr.Date).toISOString().substring(0, 10);
        }
      }
    }
    if (selectedItemsContestants.length > 0)
      setDataState({
        ...dataState,
        contestantsData: selectedItemsContestants,
      });
  };
  const isPendingCampaign =
    singleEventStore.data.results &&
    singleEventStore.data.results.campaignDetails
      ? singleEventStore.data.results.campaignDetails.status !== "active"
      : true;

  const eventCandidates =
    eventStatistics.data.results?.LatestOverallVotes?.CandidatesSummary.sort(
      (a, b) => b.Votes - a.Votes
    ) || [];

  const onActivateOrStopEvent = async () => {
    const response = await apiService({
      path: `/events/${eventId}`,
      method: "PATCH",
      data: {
        isActive: !singleEventStore.data.results.isActive,
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        dispatch(
          reduxAction({
            method: "GET",
            path: `/events/${eventId}?startDate=${startDate}&endDate=${endDate}`,
            actionType: FETCH_SINGLE_EVENT_INFO,
          })
        );
      },
    });
  };
  return (
    <div>
      {(singleEventStore.loading && !singleEventStore.data.results) ||
      (singleEventStore.loading &&
        singleEventStore.data.results.id !== eventId) ? (
        <Loading />
      ) : (
        <div>
          {/* TOp component of the event */}
          <EventComponent
            eventData={singleEventStore.data.results || {}}
            user={userInfo}
            onActivateOrStopEvent={onActivateOrStopEvent}
          />
          <br />
          {singleEventStore.data.results?.category === "Voting" &&
            singleEventStore.data.results?.status !== "pending" && (
              <>
                <RecordsContainer
                  role={userInfo.role}
                  isUpdating={
                    eventStatistics.loading && singleEventStore.data.results
                  }
                  recordsToDisplay={eventStatistics.data.results || {}}
                />

                <ContestantsChart
                  chartData={
                    eventStatistics.data.results
                      ? {
                          candidatesSummary:
                            eventStatistics.data.results.LatestOverallVotes?.CandidatesSummary.sort(
                              (a, b) => b.Votes - a.Votes
                            ) || [],
                          categorySummary:
                            eventStatistics.data.results.LatestOverallVotes?.CategorySummary.sort(
                              (a, b) => b.Votes - a.Votes
                            ) || [],
                          datesSummary:
                            eventStatistics.data.results.DailyVotes?.sort(
                              (a, b) => new Date(a.Date) - new Date(b.Date)
                            ) || [],
                          bucketsSummary:
                            eventStatistics.data.results.LatestOverallVotes?.BucketSummary?.sort(
                              (a, b) => b.Votes - a.Votes
                            ) || [],
                          voteOptsSummary:
                            eventStatistics.data.results.LatestOverallVotes
                              ?.VotingOptionsSummary || [],
                        }
                      : {}
                  }
                  role={userInfo.role}
                  isUpdating={
                    eventStatistics.loading && singleEventStore.data.results
                  }
                  isDateRange={startDate !== endDate}
                  changeContestantsForSelectedMenu={
                    changeContestantsForSelectedMenu
                  }
                />

                <ContestantsTableContainer
                  role={userInfo.role}
                  isUpdating={
                    singleEventStore.loading && singleEventStore.data.results
                  }
                  contestantsData={eventCandidates.map((candidate) =>
                    formatContestantsData(candidate)
                  )}
                />
              </>
            )}
          {singleEventStore.data.results?.category === "Concert" && (
            <>
              <ConcertContainer
                role={userInfo.role}
                eventCategory={singleEventStore.data.results.category}
                isUpdating={eventStatistics.loading && singleEventStore.data.results}
                recordsToDisplay={eventStatistics.data.results || {}} />
                
              <ConcertCharts
                chartData={eventStatistics.data.results
                  ? {
                    TierSummary:eventStatistics.data.results.tierSummary,
                    dailySummary: eventStatistics.data.results.dailySummary,
                    bucketSummary: eventStatistics.data.results.bucketSummary,
                    orderSizeSummary:eventStatistics.data.results.orderSizeSummary,
                    statusSummary: eventStatistics.data.results.statusSummary,
                    sourceSummary: eventStatistics.data.results.sourceSummary,
                    vendorSummary: eventStatistics.data.results.vendorSummary
                  }
                  : {}}
                role={userInfo.role}
                isUpdating={eventStatistics.loading && singleEventStore.data.results}
                isDateRange={startDate !== endDate}
                changeContestantsForSelectedMenu={changeContestantsForSelectedMenu} />

                <ConcertTableContainer
                  role={userInfo.role}
                  isUpdating={
                    singleEventStore.loading && singleEventStore.data.results
                  }
                  ConcertData={ eventStatistics.data.results?.vendorSummary}
                  concertTiers = { eventStatistics.data.results?.tierSummary}
                />
            </>
          )}
        </div>
      )}
    </div>
  );
};
