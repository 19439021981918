import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CustomButton from "./Buttons/customButton";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    padding: "10vh 0",
    textAlign: "center",
    width: "100%",
  },
  content: {
    display: "inline-block",
    opacity: 0.7,
  },
  buttonContainer: {
    textAlign: "center",
    width: "100%",
  },

  backTo: {
    height: "45px",
    textAlign: "center",
    textTransform: "none",
  },
  links: {
    textDecoration: "none",
  },
});

export default ({ title, path, isIndividual, pathName, customElements }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h6" align="center">
          {title || "No content Found"}
        </Typography>
        {customElements}
      </div>
      <br />
      {path && (
        <Link to={path} className={classes.links}>
          <CustomButton className={classes.backTo} color="primary">
            {isIndividual ? (
              <Typography variant="subtitle1">{pathName}</Typography>
            ) : (
              <Typography variant="subtitle1">Back to {pathName}</Typography>
            )}
          </CustomButton>
        </Link>
      )}
    </div>
  );
};
