import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// core-components
import ImageUpload from "../../../components/CustomUpload/ImageUpload";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Toast from "../../../components/MessageToast";
import { folderDate } from "../../../helpers/helperMethods";
import { uploadImageFile, resetImageStore } from "../../../redux/upload/index";

export default (props) => {
  const { defaultImage, isSmall, onClick } = props;
  const imageStore = useSelector((store) => store.uploadedImageFile);
  const dispatch = useDispatch();

  const [imageState, setImageState] = useState({
    imageError: "",
    openToast: false,
  });

  useEffect(() => {
    dispatch(resetImageStore());
  }, []);

  const handleImageSubmit = (imageData) => {
    onClick();
    const file = new FormData();
    file.append("image", imageData);
    file.append(
      "destination",
      `public_html/app/webroot/img/${folderDate}/images`
    );
    file.append("outside", true);
    dispatch(uploadImageFile(file));
    setImageState({ ...imageState, openToast: true });
  };

  const removeUploaded = async () => {
    if (imageStore.data.path) {
      setImageState({ ...imageState, removing: true, openToast: true });
      // const res = await removeFileFromStorage(imageStore.data.path);
      if (res.error) {
        Toast({
          message: `${res.errorMessage}`,
          type: "error",
          onClose: () =>
            setImageState({ ...imageState, removing: false, openToast: false }),
        });
        return true;
      } else {
        dispatch(resetImageStore());
        setImageState({
          ...imageState,
          removing: false,
          openToast: false,
          uploaded: false,
        });
      }
      return false;
    }
    return false;
  };

  let uploadImageError = imageStore.error.errors
    ? imageStore.error.errors.image[0]
    : imageStore.error.networkError;

  if (imageState.openToast) {
    if (imageStore.error.errors || imageStore.error.networkError) {
      Toast({
        message: `${uploadImageError}`,
        type: "error",
        onClose: () => setImageState({ ...imageState, openToast: false }),
      });
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} md={10} sm={10}>
          <ImageUpload
            isSmall={isSmall}
            defaultImage={defaultImage}
            imageSubmit={handleImageSubmit}
            removeUploaded={removeUploaded}
            imageTypes={
              "image/jpeg, image/png, image/jpg, image/gif, image/svg, image/bmp, image/ico"
            }
            addButtonProps={{
              color: "primary",
            }}
            changeButtonProps={{
              color: "primary",
            }}
            removeButtonProps={{
              color: "danger",
            }}
            removing={imageState.removing}
            imageError={imageState.imageError}
            isUploading={imageStore.loading}
            uploaded={imageState.uploaded || imageStore.data.path}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};
