import React, { Component } from "react";
import AppLayout from "../../../layouts/AppLayout";
import EventsContainer from "../../containers/events/allEvents";
import EventContainer from "../../containers/events/eventContainer";

export class Events extends Component {
  render() {
    const eventId = this.props.match.params.eventId;

    return eventId ? (
      <AppLayout hasBack>
        <EventContainer eventId={eventId} />
      </AppLayout>
    ) : (
      <AppLayout>
        <EventsContainer />
      </AppLayout>
    );
  }
}

export default Events;
