import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "../../../components/CustomInput/TextField";
import CustomDialog from "../../../components/Dialogs/CustomDialog";
import CustomSelect from "../../../components/CustomSelect/SimpleCustomSelect";

export default ({ onCancel, onConfirm, clientName, open, roles, loading }) => {
  const [state, setState] = useState({});
  const onChange = (ev) => {
    setState({
      ...state,
      [ev.target.name]: ev.target.value,
    });
  };

  const handleUserRoleChange = (role) => {
    setState({
      ...state,
      role: role.value,
    });
  };

  const onConfirmSend = () => {
    const inviteData = {
      name: state.name,
      email: state.email,
      role: state.role,
    };
    onConfirm(inviteData);
  };

  return (
    <div>
      <CustomDialog
        open={open || false}
        fullWidth
        title={`Invite ${clientName} user`}
        okText="Send"
        onCancel={onCancel}
        onConfirm={onConfirmSend}
        loading={loading}
        content={
          <Box>
            <TextField label="Name" name="name" fullWidth onChange={onChange} />
            <br></br>
            <TextField
              label="Email"
              fullWidth
              name="email"
              type="email"
              onChange={onChange}
            />
            <br></br>
            <CustomSelect
              onInputChange={() => {}}
              onChange={handleUserRoleChange}
              options={
                (roles &&
                  roles.map((role) => ({ label: role, value: role }))) ||
                []
              }
              value={state.role || "Select Role"}
            />
          </Box>
        }
      />
    </div>
  );
};
