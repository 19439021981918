import React from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons

// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardIcon from "../../../components/Card/CardIcon.js";
import Loading from "../../../components/isLoading.js";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "../../../assets/styles/views/dashboardStyle.js";
import { Typography } from "@material-ui/core";
import { formatNumber } from "../../../helpers/helperMethods.js";

const useStyles = makeStyles(styles);

export default ({ records, isUpdating, eventCategory, TierTickets }) => {
  const classes = useStyles();

  return records.loading ? (
    <GridContainer justify="center">
      <Loading />
    </GridContainer>
  ) : (
    <GridContainer style={{ padding: 10 }}>
      {records.slice(0,1).map((record, index) => (
        <GridItem xs={12} sm={6} md={4} key={index}>
          <Card style={{ height: "100px" }}>
            <CardHeader color={"#000"} icon>
              <CardIcon color={record.color || "warning"}>
                {record.icon || <Icon>{record.iconName}</Icon>}
              </CardIcon>
              <div className={classes.cardCategory}>
                <Typography variant="body1">
                  {record.title || "title"}
                </Typography>
              </div>
              <br></br>
              <h3 className={classes.cardTitle} style={{ textAlign: "right" }}>
                {isUpdating ? (
                  <Skeleton variant="rect" className={classes.skeleton} />
                ) : (
                  <React.Fragment>
                    <b> {formatNumber(record.value) || 0}</b>
                    <br></br>
                    {record.votes >= 0 && eventCategory !== "Concert" && (
                      <small>Votes: {formatNumber(record.votes)}</small>
                    )}
                    {/* {record.value >= 0 && eventCategory === "Concert" && (
                      <small>Tickets: {formatNumber(record.votes)}</small>
                    )} */}
                    {record.value >= 0 && eventCategory === "Concert" && (
                      <div style={{display:"flex",paddingTop:"10px"}}>
                        <small style={{marginRight:"25px"}}>Sold: {formatNumber(record.sold)}</small>
                        <small> Remaining: {formatNumber(record.remaining)}</small>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
      ))}
      {eventCategory === "Concert" && TierTickets?.map((record, index) => (
        <GridItem xs={12} sm={6} md={4} key={index}>
          <Card style={{ height: "100px" }}>
            <CardHeader color={"#000"} icon>
              <CardIcon color={record.color || "warning"}>
                {record.icon || <Icon>how_to_vote</Icon>}
              </CardIcon>
              <div className={classes.cardCategory}>
                <Typography variant="body1">
                  {record.name || "title"}
                </Typography>
              </div>
              <br></br>
              <h3 className={classes.cardTitle} style={{ textAlign: "right" }}>
                {isUpdating ? (
                  <Skeleton variant="rect" className={classes.skeleton} />
                ) : (
                  <React.Fragment>
                    <b>Tickets: {formatNumber(record.totalSeats) || 0}</b>
                    <br></br>
                    {record.votes >= 0 && eventCategory !== "Concert" && (
                      <small>Votes: {formatNumber(record.votes)}</small>
                    )}
                    {record.totalTicketSales >= 0 && eventCategory === "Concert" && (
                      <div style={{display:"flex",paddingTop:"10px"}}>
                        <small style={{marginRight:"25px"}}>Sold: {formatNumber(record.totalTicketsSold)}</small>
                        <small> Remaining: {formatNumber(record.availableSeats)}</small>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
      ))}
      {records.slice(1,10).map((record, index) => (
        <GridItem xs={12} sm={6} md={4} key={index}>
          <Card style={{ height: "100px" }}>
            <CardHeader color={"#000"} icon>
              <CardIcon color={record.color || "warning"}>
                {record.icon || <Icon>{record.iconName}</Icon>}
              </CardIcon>
              <div className={classes.cardCategory}>
                <Typography variant="body1">
                  {record.title || "title"}
                </Typography>
              </div>
              <br></br>
              <h3 className={classes.cardTitle} style={{ textAlign: "right" }}>
                {isUpdating ? (
                  <Skeleton variant="rect" className={classes.skeleton} />
                ) : (
                  <React.Fragment>
                    <b> {formatNumber(record.value) || 0}</b>
                    <br></br>
                    {record.votes >= 0 && eventCategory !== "Concert" && (
                      <small>Votes: {formatNumber(record.votes)}</small>
                    )}
                    {record.votes >= 0 && eventCategory === "Concert" && (
                      <small>Tickets: {formatNumber(record.votes)}</small>
                    )}
                  </React.Fragment>
                )}
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
      ))}
    </GridContainer>
  );
};
