import React from 'react';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    
}))(TableCell);

const useStyles = makeStyles((theme)=>({
  doNotShowInMobile:{
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
  },
  showInMobile: {
      [theme.breakpoints.down('xs')]: {
          display: 'table-cell',
      },
  },
}));

export default (props)=> {
  const {
      classes,
      order,
      orderBy,
      onRequestSort,
      headCells
  } = props;
   
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.rightAlign ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={ headCell.doNotShowInMobile && classes.doNotShowInMobile || ''}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
