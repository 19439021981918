import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "../../../components/CustomInput/TextField";
import { Grid } from "@material-ui/core";
import Remove from "@material-ui/icons/RemoveCircle";

export default ({
  paymentConfig,
  classes,
  onPaymentRadioChange,
  onPayMethodsChange,
  setPaymentConfig,
}) => {
  const [state, setState] = useState({
    activeTab:
      (paymentConfig.cardOptions && paymentConfig.cardOptions.currency[0]) ||
      "",
  });

  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,.02)",
        padding: 10,
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <ListItem>
        <ListItemText primary="Payment options" />
      </ListItem>

      <ListItem>
        <FormControl component="fieldset">
          <FormLabel component="legend">Payment Required?</FormLabel>
          <RadioGroup
            aria-label="payment options"
            defaultValue="Not required"
            name="payment"
            row
            onChange={onPaymentRadioChange}
          >
            <FormControlLabel
              value="required"
              control={
                <Radio
                  className={classes.checkBox}
                  checked={paymentConfig.required}
                />
              }
              label="Required"
            />
            <FormControlLabel
              value="notrequired"
              control={
                <Radio
                  className={classes.checkBox}
                  checked={!paymentConfig.required}
                />
              }
              label="Not required"
            />
          </RadioGroup>
        </FormControl>
      </ListItem>
      {paymentConfig.required && (
        <React.Fragment>
          <ListItem>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="paymentMethods">Payment methods</InputLabel>
              <Select
                labelId="paymentMethods"
                id="paymentMethods"
                name="paymentMethods"
                onChange={onPayMethodsChange}
                label="Payment methods"
                multiple
                value={paymentConfig.methods || []}
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Mobile money">
                  <Checkbox
                    className={classes.checkBox}
                    checked={
                      paymentConfig.methods &&
                      paymentConfig.methods.indexOf("Mobile money") > -1
                    }
                  />
                  <ListItemText primary={"Mobile money"} />
                </MenuItem>
                <MenuItem value="Visa card">
                  <Checkbox
                    className={classes.checkBox}
                    checked={
                      paymentConfig.methods &&
                      paymentConfig.methods.indexOf("Visa card") > -1
                    }
                  />
                  <ListItemText primary={"Visa card"} />
                </MenuItem>
                <MenuItem value="Master card">
                  <Checkbox
                    className={classes.checkBox}
                    checked={
                      paymentConfig.methods &&
                      paymentConfig.methods.indexOf("Master card") > -1
                    }
                  />
                  <ListItemText primary={"Master card"} />
                </MenuItem>
              </Select>
            </FormControl>
          </ListItem>

          <ListItem>
            <Grid
              container
              spacing={2}
              justify="space-around"
              style={{ marginTop: 10 }}
            >
              {paymentConfig.methods &&
                paymentConfig.methods.indexOf("Mobile money") > -1 && (
                  <Grid
                    item
                    md={5}
                    sm={12}
                    style={{
                      border: "1px solid black",
                      borderRadius: 5,
                      marginBottom: 5,
                      height: "fit-content",
                    }}
                  >
                    Mobile money options
                    <br />
                    <br />
                    <Typography>Votes options</Typography>
                    <List>
                      {paymentConfig.momoOptions &&
                        paymentConfig.momoOptions.voteOptions &&
                        paymentConfig.momoOptions.voteOptions.map((el) => (
                          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <ListItemText
                              primary={`> RWF ${el.amount}: ${el.votes} votes `}
                            />
                            <ListItemSecondaryAction
                              onClick={() => {
                                const currepts =
                                  paymentConfig.momoOptions.voteOptions.filter(
                                    (elem) => elem !== el
                                  );
                                currepts.sort((a, b) => a.votes - b.votes);
                                setPaymentConfig({
                                  ...paymentConfig,
                                  momoOptions: {
                                    ...paymentConfig.momoOptions,
                                    voteOptions: currepts,
                                  },
                                  changed: true,
                                });
                              }}
                            >
                              <Tooltip
                                title={`Remove ${el.votes} option`}
                                arrow
                              >
                                <Remove fontSize="small" color="action" />
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}

                      <ListItem>
                        <Grid container spacing={1}>
                          <Grid item sm>
                            <CustomInput
                              label="Amount"
                              value={
                                (paymentConfig.momoOptions &&
                                  paymentConfig.momoOptions.addMomoAmount) ||
                                ""
                              }
                              onChange={(e) => {
                                setPaymentConfig({
                                  ...paymentConfig,
                                  momoOptions: {
                                    ...paymentConfig.momoOptions,
                                    addMomoAmount: e.target.value,
                                  },
                                  changed: true,
                                });
                              }}
                              fullWidth
                              type="number"
                              StartAdornment={() => "RWF: "}
                            />
                          </Grid>
                          <Grid item sm>
                            <CustomInput
                              label="Votes"
                              value={
                                (paymentConfig.momoOptions &&
                                  paymentConfig.momoOptions.addMomoVotes) ||
                                ""
                              }
                              onChange={(e) => {
                                setPaymentConfig({
                                  ...paymentConfig,
                                  momoOptions: {
                                    ...paymentConfig.momoOptions,
                                    addMomoVotes: e.target.value,
                                  },
                                  changed: true,
                                });
                              }}
                              fullWidth
                              type="number"
                            />
                          </Grid>
                          <Grid item sm style={{ marginTop: 10 }}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                const currepts =
                                  paymentConfig.momoOptions.voteOptions;
                                currepts.push({
                                  amount:
                                    paymentConfig.momoOptions.addMomoAmount,
                                  votes: paymentConfig.momoOptions.addMomoVotes,
                                });
                                currepts.sort((a, b) => a.votes - b.votes);
                                setPaymentConfig({
                                  ...paymentConfig,
                                  momoOptions: {
                                    ...paymentConfig.momoOptions,
                                    voteOptions: currepts,
                                    addMomoVotes: undefined,
                                    addMomoAmount: undefined,
                                  },
                                  changed: true,
                                });
                              }}
                            >
                              Add
                            </Button>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </Grid>
                )}
              {paymentConfig.methods &&
                (paymentConfig.methods.indexOf("Visa card") > -1 ||
                  paymentConfig.methods.indexOf("Master card") > -1) && (
                  <Grid
                    item
                    md={6}
                    sm={12}
                    style={{ border: "1px solid black", borderRadius: 5 }}
                  >
                    <Typography style={{ marginBottom: 10 }}>
                      Card options
                    </Typography>

                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="cardCurrency">
                        Supported currency
                      </InputLabel>
                      <Select
                        labelId="cardCurrency"
                        id="cardCurrency"
                        name="cardCurrency"
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          const currency =
                            typeof value === "string"
                              ? value.split(",")
                              : value;
                          setPaymentConfig({
                            ...paymentConfig,
                            cardOptions: {
                              ...paymentConfig.cardOptions,
                              currency,
                            },
                            changed: true,
                          });
                          setState({
                            activeTab: currency[0],
                          });
                        }}
                        label="Currency"
                        multiple
                        value={
                          (paymentConfig.cardOptions &&
                            paymentConfig.cardOptions.currency) ||
                          []
                        }
                        renderValue={(selected) => selected.join(", ")}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="RWF">
                          <Checkbox
                            className={classes.checkBox}
                            checked={
                              paymentConfig.cardOptions &&
                              paymentConfig.cardOptions.currency.indexOf(
                                "RWF"
                              ) > -1
                            }
                          />
                          <ListItemText primary={"RWF"} />
                        </MenuItem>
                        <MenuItem value="USD">
                          <Checkbox
                            className={classes.checkBox}
                            checked={
                              paymentConfig.cardOptions &&
                              paymentConfig.cardOptions.currency.indexOf(
                                "USD"
                              ) > -1
                            }
                          />
                          <ListItemText primary={"USD"} />
                        </MenuItem>
                        <MenuItem value="EUR">
                          <Checkbox
                            className={classes.checkBox}
                            checked={
                              paymentConfig.cardOptions &&
                              paymentConfig.cardOptions.currency.indexOf(
                                "EUR"
                              ) > -1
                            }
                          />
                          <ListItemText primary={"EUR"} />
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {paymentConfig.cardOptions &&
                      paymentConfig.cardOptions.currency.length > 0 && (
                        <ListItem style={{ marginTop: 10 }}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="card payment configuration"
                              defaultValue="RWF"
                              name="cardPaymentConfig"
                              row
                              onChange={(e) => {
                                setState({
                                  activeTab: e.target.value,
                                });
                              }}
                            >
                              {paymentConfig.cardOptions.currency.map((el) => (
                                <FormControlLabel
                                  value={el}
                                  control={
                                    <Radio
                                      className={classes.checkBox}
                                      checked={state.activeTab[el]}
                                    />
                                  }
                                  label={`${el} options`}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </ListItem>
                      )}
                    {state.activeTab === "RWF" && (
                      <>
                        <List>
                          <ListItem>
                            <Typography>Votes options</Typography>
                          </ListItem>
                          {paymentConfig.cardOptions.RWF.voteOptions &&
                            paymentConfig.cardOptions.RWF.voteOptions.map(
                              (el) => (
                                <ListItem
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                  <ListItemText
                                    primary={`> RWF ${el.amount} : ${el.votes} votes `}
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                    }}
                                  />
                                  <ListItemSecondaryAction
                                    onClick={() => {
                                      const currepts =
                                        paymentConfig.cardOptions.RWF.voteOptions.filter(
                                          (elem) => elem !== el
                                        );
                                      currepts.sort(
                                        (a, b) => a.votes - b.votes
                                      );
                                      setPaymentConfig({
                                        ...paymentConfig,
                                        cardOptions: {
                                          ...paymentConfig.cardOptions,
                                          RWF: {
                                            ...paymentConfig.cardOptions.RWF,
                                            voteOptions: currepts,
                                          },
                                        },
                                        changed: true,
                                      });
                                    }}
                                  >
                                    <Tooltip
                                      title={`Remove ${el.votes} option`}
                                      arrow
                                    >
                                      <Remove fontSize="small" color="action" />
                                    </Tooltip>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            )}
                          <ListItem>
                            <Grid container spacing={1}>
                              <Grid item sm>
                                <CustomInput
                                  label="Amount"
                                  value={
                                    paymentConfig.cardOptions.RWF
                                      .addCardRwfAmount || ""
                                  }
                                  onChange={(e) => {
                                    setPaymentConfig({
                                      ...paymentConfig,
                                      cardOptions: {
                                        ...paymentConfig.cardOptions,
                                        RWF: {
                                          ...paymentConfig.cardOptions.RWF,
                                          addCardRwfAmount: e.target.value,
                                        },
                                      },
                                      changed: true,
                                    });
                                  }}
                                  fullWidth
                                  type="number"
                                  StartAdornment={() => "RWF:"}
                                />
                              </Grid>
                              <Grid item sm>
                                <CustomInput
                                  label="Votes"
                                  value={
                                    paymentConfig.cardOptions.RWF
                                      .addCardRwfVotes || ""
                                  }
                                  onChange={(e) => {
                                    setPaymentConfig({
                                      ...paymentConfig,
                                      cardOptions: {
                                        ...paymentConfig.cardOptions,
                                        RWF: {
                                          ...paymentConfig.cardOptions.RWF,
                                          addCardRwfVotes: e.target.value,
                                        },
                                      },
                                      changed: true,
                                    });
                                  }}
                                  fullWidth
                                  type="number"
                                />
                              </Grid>
                              <Grid item sm style={{ marginTop: 10 }}>
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    const currepts =
                                      paymentConfig.cardOptions.RWF.voteOptions;
                                    currepts.push({
                                      amount:
                                        paymentConfig.cardOptions.RWF
                                          .addCardRwfAmount,
                                      votes:
                                        paymentConfig.cardOptions.RWF
                                          .addCardRwfVotes,
                                    });
                                    currepts.sort((a, b) => a.votes - b.votes);
                                    setPaymentConfig({
                                      ...paymentConfig,
                                      cardOptions: {
                                        ...paymentConfig.cardOptions,
                                        RWF: {
                                          ...paymentConfig.cardOptions.RWF,
                                          voteOptions: currepts,
                                          addCardRwfVotes: undefined,
                                          addCardRwfAmount: undefined,
                                        },
                                      },
                                      changed: true,
                                    });
                                  }}
                                >
                                  Add
                                </Button>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </List>
                      </>
                    )}
                    {state.activeTab === "USD" && (
                      <>
                        <List>
                          <ListItem>
                            <Typography>Votes options</Typography>
                          </ListItem>
                          {paymentConfig.cardOptions.USD.voteOptions &&
                            paymentConfig.cardOptions.USD.voteOptions.map(
                              (el) => (
                                <ListItem
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                  <ListItemText
                                    primary={`> USD ${el.amount}: ${el.votes} votes `}
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                    }}
                                  />
                                  <ListItemSecondaryAction
                                    onClick={() => {
                                      const currepts =
                                        paymentConfig.cardOptions.USD.voteOptions.filter(
                                          (elem) => elem !== el
                                        );
                                      currepts.sort(
                                        (a, b) => a.votes - b.votes
                                      );
                                      setPaymentConfig({
                                        ...paymentConfig,
                                        cardOptions: {
                                          ...paymentConfig.cardOptions,
                                          USD: {
                                            ...paymentConfig.cardOptions.USD,
                                            voteOptions: currepts,
                                          },
                                        },
                                        changed: true,
                                      });
                                    }}
                                  >
                                    <Tooltip
                                      title={`Remove ${el.votes} option`}
                                      arrow
                                    >
                                      <Remove fontSize="small" color="action" />
                                    </Tooltip>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            )}
                          <ListItem>
                            <Grid container spacing={1}>
                              <Grid item sm>
                                <CustomInput
                                  label="Amount"
                                  value={
                                    paymentConfig.cardOptions.USD
                                      .addCardUsdAmount || ""
                                  }
                                  onChange={(e) => {
                                    setPaymentConfig({
                                      ...paymentConfig,
                                      cardOptions: {
                                        ...paymentConfig.cardOptions,
                                        USD: {
                                          ...paymentConfig.cardOptions.USD,
                                          addCardUsdAmount: e.target.value,
                                        },
                                      },
                                      changed: true,
                                    });
                                  }}
                                  fullWidth
                                  type="number"
                                  StartAdornment={() => "USD: "}
                                />
                              </Grid>
                              <Grid item sm>
                                <CustomInput
                                  label="Votes"
                                  value={
                                    paymentConfig.cardOptions.USD
                                      .addCardUsdVotes || ""
                                  }
                                  onChange={(e) => {
                                    setPaymentConfig({
                                      ...paymentConfig,
                                      cardOptions: {
                                        ...paymentConfig.cardOptions,
                                        USD: {
                                          ...paymentConfig.cardOptions.USD,
                                          addCardUsdVotes: e.target.value,
                                        },
                                      },
                                      changed: true,
                                    });
                                  }}
                                  fullWidth
                                  type="number"
                                />
                              </Grid>
                              <Grid item style={{ marginTop: 10 }}>
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    const currepts =
                                      paymentConfig.cardOptions.USD.voteOptions;
                                    currepts.push({
                                      amount:
                                        paymentConfig.cardOptions.USD
                                          .addCardUsdAmount,
                                      votes:
                                        paymentConfig.cardOptions.USD
                                          .addCardUsdVotes,
                                    });
                                    currepts.sort((a, b) => a.votes - b.votes);
                                    setPaymentConfig({
                                      ...paymentConfig,
                                      cardOptions: {
                                        ...paymentConfig.cardOptions,
                                        USD: {
                                          ...paymentConfig.cardOptions.USD,
                                          voteOptions: currepts,
                                          addCardUsdVotes: undefined,
                                          addCardUsdAmount: undefined,
                                        },
                                      },
                                      changed: true,
                                    });
                                  }}
                                >
                                  Add
                                </Button>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </List>
                      </>
                    )}
                    {state.activeTab === "EUR" && (
                      <>
                        <List>
                          <ListItem>
                            <Typography>Votes options</Typography>
                          </ListItem>
                          {paymentConfig.cardOptions.EUR.voteOptions &&
                            paymentConfig.cardOptions.EUR.voteOptions.map(
                              (el) => (
                                <ListItem
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                  <ListItemText
                                    primary={`> EUR ${el.amount}: ${el.votes} votes `}
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                    }}
                                  />
                                  <ListItemSecondaryAction
                                    onClick={() => {
                                      const currepts =
                                        paymentConfig.cardOptions.EUR.voteOptions.filter(
                                          (elem) => elem !== el
                                        );
                                      currepts.sort(
                                        (a, b) => a.votes - b.votes
                                      );
                                      setPaymentConfig({
                                        ...paymentConfig,
                                        cardOptions: {
                                          ...paymentConfig.cardOptions,
                                          EUR: {
                                            ...paymentConfig.cardOptions.EUR,
                                            voteOptions: currepts,
                                          },
                                        },
                                        changed: true,
                                      });
                                    }}
                                  >
                                    <Tooltip
                                      title={`Remove ${el.votes} option`}
                                      arrow
                                    >
                                      <Remove fontSize="small" color="action" />
                                    </Tooltip>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            )}
                          <ListItem>
                            <Grid container spacing={1}>
                              <Grid item sm>
                                <CustomInput
                                  label="Amount"
                                  value={
                                    paymentConfig.cardOptions.EUR
                                      .addCardEuroAmount || ""
                                  }
                                  onChange={(e) => {
                                    setPaymentConfig({
                                      ...paymentConfig,
                                      cardOptions: {
                                        ...paymentConfig.cardOptions,
                                        EUR: {
                                          ...paymentConfig.cardOptions.EUR,
                                          addCardEuroAmount: e.target.value,
                                        },
                                      },
                                      changed: true,
                                    });
                                  }}
                                  fullWidth
                                  type="number"
                                  StartAdornment={() => "EUR: "}
                                />
                              </Grid>
                              <Grid item sm>
                                <CustomInput
                                  label="Votes"
                                  value={
                                    paymentConfig.cardOptions.EUR
                                      .addCardEuroVotes || ""
                                  }
                                  onChange={(e) => {
                                    setPaymentConfig({
                                      ...paymentConfig,
                                      cardOptions: {
                                        ...paymentConfig.cardOptions,
                                        EUR: {
                                          ...paymentConfig.cardOptions.EUR,
                                          addCardEuroVotes: e.target.value,
                                        },
                                      },
                                      changed: true,
                                    });
                                  }}
                                  fullWidth
                                  type="number"
                                />
                              </Grid>
                              <Grid item sm style={{ marginTop: 10 }}>
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    const currepts =
                                      paymentConfig.cardOptions.EUR.voteOptions;
                                    currepts.push({
                                      amount:
                                        paymentConfig.cardOptions.EUR
                                          .addCardEuroAmount,
                                      votes:
                                        paymentConfig.cardOptions.EUR
                                          .addCardEuroVotes,
                                    });
                                    currepts.sort((a, b) => a.votes - b.votes);
                                    setPaymentConfig({
                                      ...paymentConfig,
                                      cardOptions: {
                                        ...paymentConfig.cardOptions,
                                        EUR: {
                                          ...paymentConfig.cardOptions.EUR,
                                          voteOptions: currepts,
                                          addCardEuroVotes: undefined,
                                          addCardEuroAmount: undefined,
                                        },
                                      },
                                      changed: true,
                                    });
                                  }}
                                >
                                  Add
                                </Button>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </List>
                      </>
                    )}
                  </Grid>
                )}
            </Grid>
          </ListItem>
        </React.Fragment>
      )}
    </div>
  );
};
