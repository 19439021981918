import { primaryColor, whiteColor } from "../base";
const drawerWidth = 240;

export default (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#FB8D03",
    zIndex: theme.zIndex.drawer + 1,

    "&.MuiAppBar-positionRelative": {
      position: "absolute",
      width: "97%",
      [theme.breakpoints.down("sm")]: {
        width: "95%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "93%",
      },
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: "relative",
    padding: "2vh 5px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: "3vh 10px 0vh 10px",
    maxWidth: "90%",
    [theme.breakpoints.down("sm")]: {
      padding: "3vh 10px",
      overflow: "hidden",
    },
  },

  mobileContent: {
    flexGrow: 1,
    padding: "3vh 5%",
    marginLeft: -drawerWidth,
    [theme.breakpoints.down("sm")]: {
      padding: "3vh 10px",
      overflow: "hidden",
    },
  },

  createButton: {
    backgroundColor: whiteColor,
    textTransform: "capitalize",
    color: primaryColor[0],
    float: "right",
    border: `1px solid ${whiteColor}`,
    "&:hover": {
      backgroundColor: primaryColor[0],
      border: `1px solid ${whiteColor}`,
    },
  },

  menuItem: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#efefef",
    },
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "10px",
    },
  },
  drawerContent: {
    width: "95%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
});
