import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_APP_INFO,
  GET_APP_PENDING_INVITE,
} from '../../../redux/settings/types';
import { settingsAction } from '../../../redux/settings';
import { clientsAction } from '../../../redux/clients/index';
import Loading from '../../../components/isLoading';
import AppSettingsComponent from '../../components/settings/AppSettingsComponent';
import apiService from '../../../services/apiService';
import Toastify from '../../../components/MessageToast';

export default () => {
  const dispatch = useDispatch();
  const app = useSelector((store) => store.appInfo);
  const appInvites = useSelector((store) => store.appInvites);
  useEffect(() => {
    if (!app.data.results)
      dispatch(
        settingsAction({
          method: 'GET',
          path: `/api/apps/${process.env.APP_ID}`,
          actionType: GET_APP_INFO,
        })
      );
    if (!app.data.results)
      dispatch(
        clientsAction({
          method: 'GET',
          path: `/users/invite/pending`,
          actionType: GET_APP_PENDING_INVITE,
        })
      );
  }, []);

  const addUserAppUser = async (name, email, role) => {
    const response = await apiService({
      method: 'POST',
      path: '/users/invite',
      data: {
        claims: {
          app: {
            appId: process.env.APP_ID,
            role,
          },
        },
        email,
        name,
      },
    });
    Toastify({
      message: response.message,
      type: response.error ? 'error' : 'success',
      duration: 5000,
      onClose: () => {
        dispatch(
          settingsAction({
            method: 'GET',
            path: `/api/apps/${process.env.APP_ID}`,
            actionType: GET_APP_INFO,
          })
        );
      },
    });
  };

  const onChangeUserRole = async (uid, role) => {
    const response = await apiService({
      method: 'PATCH',
      path: `/api/users/${uid}`,
      data: {
        role,
      },
    });

    if (response.error) {
      Toastify({
        message: response.message,
        type: 'error',
        duration: 5000,
      });
    } else {
      Toastify({
        message: response.message,
        type: 'success',
        duration: 3000,
        onClose: () => {},
      });
    }
  };

  const onRemoveUser = async (uid) => {
    const response = await apiService({
      method: 'DELETE',
      path: `/api/users/${uid}`,
    });

    if (response.error) {
      Toastify({
        message: response.message,
        type: 'error',
        duration: 5000,
      });
    } else {
      Toastify({
        message: response.message,
        type: 'success',
        duration: 3000,
        onClose: () => {},
      });
    }
  };

  return app.loading ? (
    <Loading />
  ) : (
    <AppSettingsComponent
      app={app.data.results || {}}
      onAddUser={addUserAppUser}
      onRemoveUser={onRemoveUser}
      onChangeUserRole={onChangeUserRole}
      appInvites={appInvites.data.results || []}
    />
  );
};
