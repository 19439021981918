import apiService from "../services/apiService";
import fullUrlService from "../services/fullUrlService";
import { IS_LOADING } from "./helperReducer/types";

export const triggerLoading = (isLoading) => (dispatch) =>
  dispatch({
    type: IS_LOADING,
    message: "loading...",
    payload: isLoading,
  });

export const fullUrlAction = ({ method, path, data, options, actionType }) => {
  return async (dispatch) =>
    await dispatch({
      type: actionType,
      payload: fullUrlService({ path, method, data, options, actionType }),
    });
};

export default ({ method, path, data, options, actionType }) => {
  return async (dispatch) =>
    await dispatch({
      type: actionType,
      payload: apiService({ path, method, data, options, actionType }),
    });
};
