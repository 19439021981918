import reducers from './reducers';

const trafficReducers = {
  overviewData: reducers.overviewDataReducer,
  pageViewsData: reducers.pageViewsDataReducer,
  sessionsData: reducers.sessionsDataReducer,
  timeOnSiteData: reducers.timeOnSiteDataReducer,
  uniqueVisitsData: reducers.uniqueVisitsDataReducer,
};

export { trafficReducers };
