import {
  FETCH_AGE_DATA,
  FETCH_COUNTRY_CITY_DATA,
  FETCH_GENDER_DATA
} from './types';

const initialState = {
  loading: false,
  data: {},
};

export default class AudienceReducers {
  static ageDataReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_AGE_DATA}_PENDING`:
        return {
          ...state,
          loading: true
        };
      case `${FETCH_AGE_DATA}_FULFILLED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      case `${FETCH_AGE_DATA}_REJECTED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      default:
        return state;
    }
  }

  static countryCityDataReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_COUNTRY_CITY_DATA}_PENDING`:
        return {
          ...state,
          loading: true
        };
      case `${FETCH_COUNTRY_CITY_DATA}_FULFILLED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      case `${FETCH_COUNTRY_CITY_DATA}_REJECTED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      default:
        return state;
    }
  }

  static genderDataReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_GENDER_DATA}_PENDING`:
        return {
          ...state,
          loading: true
        };
      case `${FETCH_GENDER_DATA}_FULFILLED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      case `${FETCH_GENDER_DATA}_REJECTED`:
        return {
          ...state,
          loading: false,
            data: action.payload,
        };
      default:
        return state;
    }
  }
}
