import { primaryColor, dangerColor, successColor } from "../base";

const footerStyle = {
  root: {
    padding: "0 5px",
  },
  smallRoot: {
    padding: "10px",
    display: "flex",

    "@media (max-width: 600px)": {
      paddingTop: "15px",
    },
  },

  imageScreen: {
    height: "50vh",
    width: "100%",
    objectFit: "cover",
    borderRadius: "3px",
    background: primaryColor[0],
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",

    "@media (max-width: 600px)": {
      marginTop: "15px",
      width: "100%",
      height: "50vh",
    },
  },

  formImageScreen: {
    maxHeight: "35vh",
    width: "100%",
    objectFit: "cover",
    borderRadius: "3px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",

    "@media (max-width: 900px)": {
      width: "100%",
      height: "30vh",
    },

    "@media (max-width: 600px)": {
      marginTop: "15px",
      width: "100%",
      height: "50vh",
    },
  },

  fileInfo: {
    padding: "10px",
  },

  errorText: {
    color: dangerColor[0],
    padding: "10px 0",
  },

  infoText: {
    color: "#C6C3C8",
    wordBreak: "break-all",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },

  uploadedImageFileName: {
    color: primaryColor[0],
    wordBreak: "break-all",

    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },

  actionButtons: {
    marginTop: "10px",

    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  doNotShow: {
    display: "none",
  },
};
export default footerStyle;
