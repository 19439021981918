import { searchBarColor } from '../base';

export default (theme) => ({
  searchContainer: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 261,
    height: '42px',
    background: searchBarColor,
    borderRadius: '7px',

    // [theme.breakpoints.down('md')]: {
    //   fontSize: '10px',
    //   height: '100%',
    //   width: 400,
    // },
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '10px',
    //   width: 400,
    //   height: '100%',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '10px',
    //   width: 400,
    //   height: '100%',
    // },
    // '@media (max-width:475px)': {
    //   width: '90%',
    // },
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    [theme.breakpoints.down('md')]: {
      // width: '100%',
    },
  },

  iconButton: {
    padding: 7,
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
  },
});
