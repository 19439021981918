import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: theme.spacing(2),
    minWidth: 120,
  },
}));

export default (props) => {
  const {
    onChangeMenu,
    value,
    headerMenus,
    hasSecondary,
    onChangeSecondaryMenu,
    secondaryValue,
    secondaryHeaderMenus,
  } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    onChangeMenu(event.target.value);
  };

  const handleSecondaryChange = (event) => {
    onChangeSecondaryMenu(event.target.value);
  };

  return (
    <div className={classes.root}>
      <span className={classes.initialDimensionHolder}>
        <FormControl className={classes.formControl}>
          <Select
            value={value}
            onChange={handleChange}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ "aria-label": "Without label" }}
          >
            {headerMenus.map((menu) => (
              <MenuItem value={menu.id} key={menu.id}>
                {menu.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </span>

      {hasSecondary && (
        <span className={classes.initialDimensionHolder}>
          <FormControl className={classes.formControl}>
            <Select
              value={secondaryValue}
              onChange={handleSecondaryChange}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ "aria-label": "Without label" }}
            >
              {secondaryHeaderMenus.map((menu) => (
                <MenuItem value={menu.id} key={menu.id}>
                  {menu.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
      )}
    </div>
  );
};
