import React from "react";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Launch from "@material-ui/icons/Launch";
import Public from "@material-ui/icons/Public";
import { Avatar } from "@material-ui/core";
import { createData } from "../../../../helpers/helperMethods";
import DataTableComponent from "../../../components/data/DataTableComponent";
import ListItemMenu from "../../../../components/Menu/ListItemMenu";

export default (props) => {
  const {
    isUpdating,
    data,
    notActive,
    noContentCustomElements,
    generalUrl,
    onLaunchEvent,
    onDeleteEvent,
    onActivateOrStopEvent,
  } = props;

  const headCells = [
    {
      id: "name",
      rightAlign: false,
      label: "Name",
    },
    {
      id: "category",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Category",
    },
    {
      id: "payment",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Payment",
    },
    {
      id: "startDate",
      rightAlign: true,
      label: "Start Date",
    },
    {
      id: "endDate",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "End Date",
    },
    // {
    //   id: "venue",
    //   rightAlign: true,
    //   doNotShowInMobile: true,
    //   label: "Venue",
    // },
    {
      id: "actions",
      rightAlign: true,
      doNotShowInMobile: true,
      label: "Actions",
    },
  ];
  const rows = data
    ? data.map((elt) =>
        createData(
          elt.id,
          {
            content1: (
              <Avatar
                alt={elt.name || "Img"}
                src={elt.coverPhoto}
                variant="rounded"
              />
            ),
            content2: elt.name,
          },
          elt.category,
          elt.payment?.required ? "Required" : "Not Required",
          elt?.startDate?.substring(0, 10),
          elt?.endDate?.substring(0, 10),
          // elt.venue || "Unset"
          <ListItemMenu
            menu={[
              {
                icon: <Edit fontSize="small" />,
                primary: "Edit",
                link: `/events/${elt.id}/edit`,
              },
              {
                icon: <Launch fontSize="small" />,
                primary: "Launch",
                onClick: () => onLaunchEvent(elt.id),
                hidden: elt.status === "published",
              },
              {
                icon: elt.isActive ? (
                  <Launch fontSize="small" />
                ) : (
                  <Launch fontSize="small" />
                ),
                primary: elt.isActive ? "Stop event" : "Activate event",
                onClick: () => onActivateOrStopEvent(elt.id, elt.isActive),
                hidden: elt.category !== "Voting" || elt.status !== "published",
              },
              {
                icon: <Public fontSize="small" />,
                primary: "Promotions",
                link: `/campaigns/${elt.id}/edit`,
                hidden: notActive,
              },
              {
                icon: <Delete fontSize="small" />,
                primary: "Delete",
                onClick: () => onDeleteEvent(elt.id),
                hidden: notActive,
              },
            ]}
          />
        )
      )
    : [];

  return (
    <DataTableComponent
      isClickable
      notActive={notActive}
      hasLongTitles
      isUpdating={isUpdating}
      rows={rows}
      headCells={headCells}
      title="Events"
      noContentCustomElements={noContentCustomElements}
      generalUrl={generalUrl}
    />
  );
};
