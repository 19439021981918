import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { GET_ALL_CLIENTS } from "../../../redux/clients/types";
import { clientsAction } from "../../../redux/clients";
import { GET_APP_INFO } from "../../../redux/settings/types";
import { settingsAction } from "../../../redux/settings";
import Loading from "../../../components/isLoading";
import ClientsListComponent from "../../components/clients/ClientsListComponent";
import apiService from "../../../services/apiService";
import Toastify from "../../../components/MessageToast";
import TableView from "../../../components/Table/TableView";
import ListItemMenu from "../../../components/Menu/ListItemMenu";
import identityService from "../../../services/identityService";
import CustomButton from "../../../components/Buttons/customButton";
import CustomDialog from "../../../components/Dialogs/CustomDialog";
import TextField from "../../../components/CustomInput/TextField";
import InviteClientUser from "../../components/clients/InviteClientComponent";
import DeleteConfirmDialog from "../../../components/Dialogs/DeleteConfirm";

export default () => {
  const [state, setState] = useState({
    createClientModel: false,
    inviteClientModel: false,
    selectedClient: {},
    searchTerm: "",
  });
  const dispatch = useDispatch();
  const clients = useSelector((store) => store.allClients);
  const appInfo = useSelector((store) => store.appInfo);
  useEffect(() => {
    if (!clients.data.results)
      dispatch(
        settingsAction({
          method: "GET",
          path: "/api/orgs",
          actionType: GET_ALL_CLIENTS,
        })
      );
    if (!appInfo.data.results)
      dispatch(
        settingsAction({
          method: "GET",
          path: `/api/apps/${process.env.APP_ID}`,
          actionType: GET_APP_INFO,
        })
      );
  }, []);

  const toggleCreateClient = () => {
    setState({ ...state, createClientModel: !state.createClientModel });
  };

  const onCreateClient = () => {
    onClientCreate({
      name: state.name,
      website: state.website,
      phoneNumber: state.phoneNumber,
      email: state.email,
    });
  };

  const onInputsChange = (ev) => {
    setState({ ...state, [ev.target.name]: ev.target.value });
  };

  const onClientCreate = async (clientData) => {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await identityService({
      path: "api/orgs",
      method: "POST",
      data: clientData,
    });
    Toastify({
      type: response.error ? "error" : "success",
      message: response.message,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error) {
          toggleCreateClient();
          dispatch(
            settingsAction({
              method: "GET",
              path: "/api/orgs",
              actionType: GET_ALL_CLIENTS,
            })
          );
        }
      },
    });
  };

  const toggleInviteClient = () => {
    setState({
      inviteClientModel: !state.inviteClientModel,
    });
  };
  const onInviteClient = (inviteData) => {
    sendClientInvite({
      ...inviteData,
      orgId: state.selectedClient.id,
    });
  };

  const setInviteState = (client) => {
    setState((prev) => ({
      ...prev,
      inviteClientModel: true,
      selectedClient: client,
    }));
  };

  const sendClientInvite = async (inviteData) => {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await identityService({
      path: `/api/orgs/${inviteData.orgId}/users`,
      method: "POST",
      data: {
        name: inviteData.name,
        email: inviteData.email,
        role: inviteData.role,
      },
    });
    Toastify({
      type: response.error ? "error" : "success",
      message: response.message,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error) {
          toggleInviteClient();
          dispatch(
            settingsAction({
              method: "GET",
              path: "/api/orgs",
              actionType: GET_ALL_CLIENTS,
            })
          );
        }
      },
    });
  };

  const onEnableDisableStats = async (clientId, status) => {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await identityService({
      path: `/api/orgs/${clientId}`,
      method: "PATCH",
      data: { canViewStats: status },
    });
    Toastify({
      type: response.error ? "error" : "success",
      message: response.message,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error) {
          dispatch(
            settingsAction({
              method: "GET",
              path: "/api/orgs",
              actionType: GET_ALL_CLIENTS,
            })
          );
        }
      },
    });
  };

  const headCells = [
    {
      id: "name",
      label: "Name",
      disablePadding: false,
      numeric: false,
    },
    {
      id: "website",
      label: "website",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "email",
      label: "email",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "phone",
      label: "Phone Number",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "users",
      label: "Users",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "created",
      label: "Created",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "action",
      label: "",
      disablePadding: true,
      numeric: false,
    },
  ];
  const rows = clients.data.results?.map((org) => ({
    name: org.name,
    website: org.website,
    email: org.email,
    phone: org.phoneNumber,
    users: org.users?.length || 0,
    created: org.createdAt,
    action: (
      <ListItemMenu
        menu={[
          {
            primary: "Details",
            link: `/orgs/${org.id}`,
          },
          {
            primary: "Invite User",
            onClick: () => setInviteState(org),
          },
          {
            primary: org.canViewStats ? "Disable stats" : "Enable stats",
            onClick: () => onEnableDisableStats(org.id, !org.canViewStats),
          },
          {
            primary: "Visit website",
            onClick: () => window.open(org.website, "_blank"),
          },
        ]}
      />
    ),
  }));

  return clients.loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <Grid container justify="flex-end">
        <CustomButton color="primary" onClick={toggleCreateClient}>
          Create organization
        </CustomButton>
      </Grid>
      <TableView
        title="Noneho Organizations"
        headCells={headCells}
        rows={rows || []}
        dense
      />

      <CustomDialog
        open={state.createClientModel || false}
        fullWidth
        title="Create new organization"
        okText="Create"
        onCancel={toggleCreateClient}
        onConfirm={onCreateClient}
        loading={state.loading}
        content={
          <Box>
            <TextField
              label="Name"
              name="name"
              fullWidth
              onChange={onInputsChange}
            />
            <TextField
              label="Website"
              fullWidth
              name="website"
              type="url"
              onChange={onInputsChange}
            />
            <TextField
              label="Email"
              fullWidth
              name="email"
              type="email"
              onChange={onInputsChange}
            />
            <TextField
              label="Phone"
              fullWidth
              name="phoneNumber"
              type="tel"
              onChange={onInputsChange}
            />
          </Box>
        }
      />

      {state.inviteClientModel && (
        <InviteClientUser
          onCancel={toggleInviteClient}
          onConfirm={onInviteClient}
          clientName={
            state.selectedClient.clientName || state.selectedClient.name
          }
          open={state.inviteClientModel}
          roles={appInfo.data.results?.roles || []}
          loading={state.loading}
        />
      )}
    </React.Fragment>
  );
};
