import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button } from "@material-ui/core";
import { primaryColor, whiteColor } from "../assets/styles/base";
import MessageToast from "../components/MessageToast";

export const findPercentage = (nbrGender, male, female, unknown) => {
  const sum = parseInt(male) + parseInt(female) + parseInt(unknown);
  return Math.round(((nbrGender / sum) * 100 + Number.EPSILON) * 100) / 100;
};

export const createData = (
  id,
  column1,
  column2,
  column3,
  column4,
  column5,
  column6,
  column7,
  column8,
  column9
) => ({
  id,
  column1,
  column2,
  column3,
  column4,
  column5,
  column6,
  column7,
  column8,
  column9,
});

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const folderDate = new Date()
  .toISOString()
  .replace("-", "")
  .substring(0, 6);

export const capitalizeFirstLetter = (string) =>
  string.replace(/^./, string[0].toUpperCase());

export const handleDates = (state) => {
  let startDate = moment(
    state.date && state.date.startDate ? state.date.startDate : state.date
  )
    .subtract(6, "d")
    .format("YYYY-MM-DD");
  let endDate = moment(
    state.date && state.date.endDate ? state.date.endDate : state.date
  ).format("YYYY-MM-DD");

  if (state.today) {
    startDate = moment().format("YYYY-MM-DD");
    endDate = moment().format("YYYY-MM-DD");
  } else if (state.daily) {
    startDate = moment(state.date).subtract(1, "d").format("YYYY-MM-DD");
    endDate = moment(state.date).subtract(1, "d").format("YYYY-MM-DD");
  } else if (state.weekly) {
    startDate = moment(state.date).subtract(7, "d").format("YYYY-MM-DD");
  } else if (state.monthly) {
    startDate = moment(state.date).subtract(1, "M").format("YYYY-MM-DD");
  } else if (state.yearly) {
    startDate = moment(state.date).subtract(1, "y").format("YYYY-MM-DD");
  }
  return { startDate, endDate };
};

export const formatDate = (date) => moment(date).format("MMM Do YYYY");
export const formatNumber = (data) =>
  data === 0 || !data ? 0 : String(data).replace(/(.)(?=(\d{3})+$)/g, "$1,");

export const CreateButton = ({ styles, text, handleOnClick }) => (
  <Button
    aria-controls="simple-menu"
    aria-haspopup="true"
    onClick={handleOnClick}
    style={{
      ...styles,
      background: primaryColor[0],
      color: whiteColor,
      padding: "10px 20px",
    }}
  >
    {text || "Create New Campaign"}
  </Button>
);

export const shortenText = (text = "", limit = 20) =>
  text.length >= limit ? `${text.substring(0, limit)}...` : text;

export const compareBanners = (banData1, banData2) => {
  if (
    banData1.caption === banData2.caption &&
    banData1.link === banData2.link &&
    banData1.url === banData2.url
  ) {
    return true;
  }
  return false;
};

export const compareAdvertorials = (advData1, advData2) => {
  if (
    advData1.title === advData2.title &&
    advData1.summary === advData2.summary &&
    advData1.photoUrl === advData2.photoUrl &&
    advData1.content === advData2.content
  ) {
    return true;
  }
  return false;
};

export const openLinkInNewTab = (url) => {
  if (url) {
    return window.open(url ? url : "https://demand.noneho.com/");
  }

  return (
    <MessageToast
      message="It Seem like the Link is not yet found"
      type="warning"
      duration={3000}
    />
  );
};
