import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

export default () => {
  return (
    <div
      style={{
        backgroundColor: '#fff',
        borderRadius: '6px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant='h4'>Noneho Dashboard</Typography>
      <Typography variant='h6'>Access Denied </Typography>
      <Typography variant='subtitle1'>
        Dear user, You're not allowed to access this content, please login or
        contact your administrator
      </Typography>
      <a
        href={`${process.env.IDENTITY_UI}/login?redUrl=${window.location.protocol}//${window.location.host}${window.location.pathname}`}
      >
        <Button color='primary' variant='contained'>
          Login
        </Button>
      </a>
    </div>
  );
};
