import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';

// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';

// core components
import AdminNavbarLinks from './AdminNavbarLinks.js';
import CustomButton from '../Buttons/customButton';
// import SearchBar from "../searchBar";

import adminNavbarStyle from '../../assets/styles/components/adminNavbarStyle';
import OrgHeaderSelect from '../../views/containers/headerSelect/OrgheaderSelect';

function AdminNavbar({ ...props }) {
  const { classes, color, rtlActive } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    ' ' +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden mdUp implementation='css'>
          <CustomButton
            className={classes.appResponsive}
            color='transparent'
            justIcon
            aria-label='open drawer'
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </CustomButton>
        </Hidden>

        <Hidden smDown implementation='css'>
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <CustomButton
                justIcon
                round
                color='white'
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </CustomButton>
            ) : (
              <CustomButton
                justIcon
                round
                color='white'
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </CustomButton>
            )}
          </div>
        </Hidden>

        {props.userProfile.claims && props.userProfile.claims.userApp.role && (
          <OrgHeaderSelect />
        )}

        <div className={classes.flex}></div>

        <AdminNavbarLinks
          userProfile={props.userProfile}
          logout={props.logout}
          notifications={props.notifications}
          onNotificationClick={props.onNotificationClick}
        />
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  handleHeaderSelect: PropTypes.func,
};

export default withStyles(adminNavbarStyle)(AdminNavbar);
