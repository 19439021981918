import React, { useEffect, useState } from "react";
import { IconButton, withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import cx from "classnames";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import appStyle from "../assets/styles/layouts/adminStyle";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import Toast from "../components/MessageToast";
import routes from "../components/Sidebar/sideBarRoutes";
import NotAllowedFB from "./NoAllowedFB";
import { logout } from "../services/authService";
import identityService from "../services/identityService";
import { setNotifications } from "../redux/notifications/index";
import FixedAlert from "../components/alert";
import { sendEmailVerification } from "../services/authService";
import { ChevronLeft } from "@material-ui/icons";

const logo = "https://inyarwandamusicdev.firebaseapp.com/assets/img/logo.png";

const SimpleLayout = (props) => {
  const { classes, MainContent, history, location, match, hasBack, ...rest } =
    props;
  const { photo, displayName, email, uid, claims, emailVerified } =
    JSON.parse(localStorage.getItem("au")) || {};
  const [state, setState] = useState({
    mobileOpen: false,
    miniActive: false,
    color: "blue",
    bgColor: "black",
    hasImage: true,
    fixedClasses: "dropdown",
    loading: false,
  });

  const dispatch = useDispatch();
  const mainPanel =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: state.miniActive,
      [classes.mainPanelWithPerfectScrollbar]: false,
    });

  const handleDrawerToggle = () => {
    setState({
      ...state,
      mobileOpen: !state.mobileOpen,
    });
  };

  const sidebarMinimize = () => {
    setState({ ...state, miniActive: !state.miniActive });
  };

  const handleLogout = async () => {
    const resp = await identityService({
      path: "/auth/logout",
      method: "GET",
    });
    if (resp.error) {
      Toast({
        message: resp.message,
        type: "error",
      });
    } else {
      const response = await logout();
      if (response.error) {
        Toast({
          message: response.message,
          type: "error",
        });
      } else {
        localStorage.removeItem("au");
        window.location.assign(
          `${process.env.IDENTITY_UI}/login?redUrl=${window.location.protocol}//${window.location.host}`
        );
      }
    }
  };

  const notificationState = useSelector((store) => store.notificationsState);
  const onNotificationClick = (notification) => {
    const currentNotifs = notificationState.notifications.filter(
      (notif) => JSON.stringify(notif) !== JSON.stringify(notification)
    );
    const redirect = (dispatch) =>
      new Promise((resolve, reject) => {
        dispatch(setNotifications(currentNotifs));
        resolve();
      });
    dispatch(setNotifications(currentNotifs));
    if (notification.click_action) {
      const url = new URL(notification.click_action);
      if (url.hostname === window.location.hostname) {
        redirect(dispatch).then(() => {
          history.push(url.pathname);
        });
      } else {
        redirect(dispatch).then(() => {
          window.location.assign(notification.click_action);
        });
      }
    }
  };

  const onSendEmailVerification = async () => {
    const response = await sendEmailVerification();
    Toast({
      type: response.error ? "error" : "success",
      message: response.message,
    });
  };

  const userProfile = {
    image: photo,
    name: displayName,
    email: email,
    claims: claims || {},
    emailVerified: emailVerified,
    canViewStats: claims && claims.userOrg && claims.userOrg.canViewStats,
  };

  const isAllowed = true;
  const hookHistory = useHistory();
  const handleBackArrow = () => hookHistory.goBack();
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Creative Tim"}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={state.mobileOpen}
        color={state.color}
        bgColor={state.bgColor}
        miniActive={state.miniActive}
        userProfile={{
          ...userProfile,
          image:
            (userProfile.claims.userOrg && userProfile.claims.userOrg.logo) ||
            userProfile.image,
          name:
            (userProfile.claims.userOrg && userProfile.claims.userOrg.name) ||
            userProfile.name,
        }}
        logout={handleLogout}
        {...rest}
      />
      <div className={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize}
          miniActive={state.miniActive}
          handleDrawerToggle={handleDrawerToggle}
          userProfile={userProfile}
          logout={handleLogout}
          notifications={notificationState.notifications}
          onNotificationClick={onNotificationClick}
          {...rest}
        />

        <main className={classes.content}>
          {hasBack && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleBackArrow}
            >
              <ChevronLeft fontSize="large" />
            </IconButton>
          )}

          {!emailVerified && (
            <FixedAlert
              type="warning"
              title="Verify Your Email"
              message="Your email is not verified, click on the link in your inbox. If you did not recieve it, click resend."
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={onSendEmailVerification}
                >
                  RESEND
                </Button>
              }
            />
          )}
          {state.loading ? (
            <Grid container justify="center" alignItems="center">
              <CircularProgress variant="indeterminate" />
            </Grid>
          ) : isAllowed ? (
            props.children
          ) : (
            <NotAllowedFB />
          )}
          {/* <Toolbar className={classes.footerToolbar} /> */}
        </main>
      </div>
    </div>
  );
};

export default withStyles(appStyle)(SimpleLayout);
