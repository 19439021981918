import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CustomCard from '../../../components/Card/CustomCard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Grid from '@material-ui/core/Grid';
import ListItemMenu from '../../../components/Menu/ListItemMenu';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CustomDialog from '../../../components/Dialogs/CustomDialog';
import TextField from '../../../components/CustomInput/TextField';
import CustomSelect from '../../../components/CustomSelect/SimpleCustomSelect';
import moment from 'moment';
import componentAllowed from '../../../helpers/authorization/componentAllowed';

export default ({
  app,
  onAddUser,
  appInvites,
  onRemoveUser,
  onChangeUserRole,
}) => {
  const [state, setState] = React.useState({ model: false });
  const handleUserRoleChange = (selectedOption) => {
    setState({ ...state, selectedRole: selectedOption.value });
  };
  const onEmailChange = (ev) => {
    setState({ ...state, [ev.target.name]: ev.target.value });
  };

  const addUserComponent = () => {
    return (
      <Box>
        <TextField
          label='Name'
          fullWidth
          name='name'
          onChange={onEmailChange}
        />
        <TextField
          label='Email'
          fullWidth
          type='email'
          name='email'
          onChange={onEmailChange}
        />
        <CustomSelect
          onInputChange={() => {}}
          onChange={handleUserRoleChange}
          options={
            (app.roles &&
              app.roles.map((role) => ({ label: role, value: role }))) ||
            []
          }
          value={state.selectedRole || 'Select Role'}
        />
      </Box>
    );
  };

  const changeUserRoleComponent = () => {
    return (
      <Box>
        <TextField
          label='Email'
          fullWidth
          type='email'
          value={state.email}
          disabled
        />
        <CustomSelect
          onInputChange={() => {}}
          onChange={handleUserRoleChange}
          options={
            (app.roles &&
              app.roles.map((role) => ({ label: role, value: role }))) ||
            []
          }
          value={state.selectedRole || 'Select Role'}
        />
      </Box>
    );
  };

  const toggleModel = (action) => {
    if (action.changeRole)
      setState({
        ...state,
        selectedRole: null,
        model: !state.model,
        changedUser: action.uid,
        email: action.email,
      });
    else
      setState({
        ...state,
        selectedRole: null,
        email: null,
        model: !state.model,
        changedUser: null,
      });
  };

  const onConfirm = () => {
    if (state.changedUser)
      onChangeUserRole(state.changedUser, state.selectedRole);
    else onAddUser(state.name, state.email, state.selectedRole);
    toggleModel({});
  };

  return (
    <Box>
      <Typography variant='h6'>Application Info</Typography>
      <CustomCard
        body={
          <Box>
            <Typography variant='subtitle1'>{app.name}</Typography>
            <Grid container>
              <Grid item md={6} sm={12}>
                <List>
                  <ListItem>
                    <ListItemText
                      disableTypography
                      secondary={
                        <React.Fragment>
                          <Typography variant='body2'>ID: {app.id}</Typography>
                          <Typography variant='body2'>
                            Users: {app.users ? app.users.length : 0}
                          </Typography>
                          <Typography variant='body2'>
                            Clients: {app.clients ? app.clients.length : 0}
                          </Typography>
                          <Typography variant='body2'>
                            Created: {moment(app.createdAt).fromNow()}
                          </Typography>
                          <Typography variant='body2'>
                            Modified: {moment(app.updatedAt).fromNow()}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Divider />
            <Box marginTop={2}>
              <Typography variant='subtitle1'>Application Users</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={8} xs={12}>
                <CustomCard
                  body={
                    <Box>
                      <Typography variant='subtitle2'>Active Users</Typography>
                      <List>
                        {app.users &&
                          app.users.map((user, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                primary={user.name}
                                disableTypography
                                secondary={
                                  <React.Fragment>
                                    <Typography variant='body2'>
                                      Email:
                                      <a
                                        href={`mailto:${user.email}`}
                                        target='_blank'
                                      >
                                        {user.email}
                                      </a>
                                    </Typography>
                                    <Typography variant='body2'>
                                      Role: {user.role}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                              {componentAllowed(['owner', 'admin']) && (
                                <ListItemSecondaryAction>
                                  <ListItemMenu
                                    menu={[
                                      {
                                        primary: 'Change role',
                                        onClick: () =>
                                          toggleModel({
                                            ...user,
                                            changeRole: true,
                                          }),
                                      },
                                      {
                                        primary: 'Remove user',
                                        onClick: () => onRemoveUser(user.uid),
                                      },
                                    ]}
                                  />
                                </ListItemSecondaryAction>
                              )}
                            </ListItem>
                          ))}
                      </List>
                      {componentAllowed(['owner', 'admin']) && (
                        <Grid container justify='flex-end'>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={toggleModel}
                          >
                            Add
                          </Button>
                        </Grid>
                      )}
                    </Box>
                  }
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <CustomCard
                  body={
                    <Box>
                      <Typography variant='subtitle2'>
                        Pending Invitations
                      </Typography>
                      {appInvites && appInvites.length > 0 && (
                        <List>
                          {appInvites.map((inv, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                primary={inv.name}
                                disableTypography
                                secondary={
                                  <React.Fragment>
                                    <Typography variant='body2'>
                                      Email:
                                      <a
                                        href={`mailto:${inv.email}`}
                                        target='_blank'
                                      >
                                        {inv.email}
                                      </a>
                                    </Typography>
                                    <Typography variant='body2'>
                                      Role: {inv.role}
                                    </Typography>
                                    <Typography variant='body2'>
                                      Invited:{' '}
                                      {moment(inv.date).format('LLL') ||
                                        '00:00 AM'}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Box>
        }
      />

      <CustomDialog
        title={state.changedUser ? 'Change user role' : 'Add user to app'}
        okText={state.changedUser ? 'Apply' : 'Send'}
        open={state.model}
        fullWidth
        content={
          state.changedUser ? changeUserRoleComponent() : addUserComponent()
        }
        onCancel={toggleModel}
        onConfirm={onConfirm}
      />
    </Box>
  );
};
