import React from "react";
import { Bar } from "react-chartjs-2";
import { Grid, Typography } from "@material-ui/core";
import HeaderSelect from "../../../components/CustomSelect/HeaderSelect";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/styles/components/chartStyle";
import NoContent from "../../../components/NoContent";

const useStyles = makeStyles(styles);

export default ({
  data,
  width,
  title,
  dimensions,
  onDimensionChange,
  valueOfDimension,
  secondaryDimensions,
  onSecondaryDimensionChange,
  valueOfSecondaryDimension,
}) => {
  const classes = useStyles();

  return (
    <div style={{ width: width || "100%", padding: 10 }}>
      <div className={classes.headContainer}>
        <Typography className={classes.title}> {title} </Typography>
        <span className={classes.dimensionSelector}>
          {dimensions && (
            <HeaderSelect
              hasSecondary
              onChangeMenu={(selected) => onDimensionChange(selected)}
              value={valueOfDimension}
              headerMenus={dimensions}
              onChangeSecondaryMenu={(selected) =>
                onSecondaryDimensionChange(selected)
              }
              secondaryValue={valueOfSecondaryDimension}
              secondaryHeaderMenus={secondaryDimensions}
            />
          )}
        </span>
      </div>

      <div className={classes.chartContainer}>
        {data.labels[0] ? (
          <Bar
            data={data}
            options={{
              maintainAspectRatio: true,
              animation: true,
              hover: true,
              responsive: true,
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      fontColor: "#2b2929",
                    },
                  },
                ],
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      fontColor: "#2b2929",
                    },
                  },
                ],
              },
            }}
          />
        ) : (
          <NoContent title="No Content" />
        )}
      </div>
    </div>
  );
};
