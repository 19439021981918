export default {
  headContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  title: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
  },
  dimensionSelector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  chartContainer: {
    marginTop: 20,
  },
};
