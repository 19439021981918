import { SET_NOTIFICATIONS } from './types';

export const notificationsReducer = (state = { notifications: [] }, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
};
