import React, { useEffect, useState } from "react";
import Loading from "../../../components/isLoading";
import MultiRecordsDoughnutChartComponent from "../../components/stats/MultiRecordsDoughnutChart";
import NoContent from "../../../components/NoContent";
import { formatChartData } from "../../../helpers/formatChartData";
import { findPercentage } from "../../../helpers/helperMethods";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { whiteColor } from "../../../assets/styles/base";

import MultiRecordsChartComponent from "../../components/stats/MultiRecordsChart";
import HeaderSelect from "../../../components/CustomSelect/HeaderSelect";
import GridContainer from "../../../components/Grid/GridContainer";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/styles/components/chartStyle";
import reduxAction from "../../../redux/action";
import { FETCH_PUBLISHER_DEMOGRAPHICS_INFO } from "../../../redux/publishers/types";

const useStyles = makeStyles(styles);

export default (props) => {
  const classes = useStyles();
  const { dataTitle, selectedPublisher, startDate, endDate, chartWidth } =
    props;
  const dimensions = [
    { id: "inyarwanda.com", name: "Inyarwanda.com" },
    { id: "facebook", name: "Facebook" },
  ];
  const dispatch = useDispatch();

  const [state, setState] = useState({
    selectedDimension: { name: "inyarwanda.com", identifier: "web" },
    selectedSecondaryDimension: "visits",
    dataFor: "visits",
  });

  const demographicsInfoStore = useSelector((store) => store.demographicsInfo);

  useEffect(() => {
    dispatch(
      reduxAction({
        method: "GET",
        path: `/publishers/${selectedPublisher}/data/${state.selectedDimension.identifier}?metric=demographic&&startDate=${startDate}&endDate=${endDate}`,
        actionType: FETCH_PUBLISHER_DEMOGRAPHICS_INFO,
      })
    );
  }, [selectedPublisher, state.selectedDimension.identifier, startDate]);

  const handleDimensionChange = (selected) => {
    setState((prevState) => ({
      ...state,
      selectedDimension: {
        name: selected,
        identifier:
          prevState.selectedDimension.identifier === "fb" ? "web" : "fb",
      },
      selectedSecondaryDimension:
        selected === "facebook" ? "page-impression" : "visits",
    }));
  };

  const secondaryDimensions =
    state.selectedDimension.name === "facebook"
      ? [{ id: "page-impression", name: "Page Impressions" }]
      : [{ id: "visits", name: "Visits" }];

  const onSecondaryDimensionChange = (selected) =>
    setState({
      ...state,
      selectedSecondaryDimension: selected,
      dataFor: selected,
    });

  let data = {};
  if (demographicsInfoStore.data.results) {
    if (
      state.selectedDimension.identifier === "fb" &&
      demographicsInfoStore.data.results.genderSummary[0] &&
      demographicsInfoStore.data.results.genderSummary[0].impressions
    ) {
      const maleImpressions =
        demographicsInfoStore.data.results.genderSummary.find(
          (elt) => elt.gender === "male"
        ).impressions;
      const femaleImpressions =
        demographicsInfoStore.data.results.genderSummary.find(
          (elt) => elt.gender === "female"
        ).impressions;
      const unknownsImpressions =
        demographicsInfoStore.data.results.genderSummary.find(
          (elt) => elt.gender === "unknown"
        ).impressions;

      const percentageMale = findPercentage(
        maleImpressions,
        maleImpressions,
        femaleImpressions,
        unknownsImpressions
      );
      const percentageFemale = findPercentage(
        femaleImpressions,
        maleImpressions,
        femaleImpressions,
        unknownsImpressions
      );
      const percentageUnknowns = findPercentage(
        unknownsImpressions,
        maleImpressions,
        femaleImpressions,
        unknownsImpressions
      );
      data = {
        labels: [
          `Male: ${percentageMale}%`,
          `Female: ${percentageFemale}%`,
          `Others: ${percentageUnknowns}%`,
        ],
        datasets: [
          {
            label: " total: ",
            data: [maleImpressions, femaleImpressions, unknownsImpressions],
            backgroundColor: ["#1977D1", "#641D48", "#FF9800"],
            borderColor: ["#1977D1", "#641D48", "#FF9800"],
            borderWidth: 1,
          },
        ],
      };
    } else {
      let maleVisits = demographicsInfoStore.data.results.genderSummary.find(
        (elt) => elt.gender === "male"
      );
      maleVisits = maleVisits ? maleVisits.visits : 0;
      let femaleVisits = demographicsInfoStore.data.results.genderSummary.find(
        (elt) => elt.gender === "female"
      );
      femaleVisits = femaleVisits ? femaleVisits.visits : 0;
      const percentageMale = findPercentage(
        maleVisits,
        maleVisits,
        femaleVisits,
        0
      );
      const percentageFemale = findPercentage(
        femaleVisits,
        femaleVisits,
        maleVisits,
        0
      );

      data = {
        labels: [
          `Male: ${percentageMale || 0}%`,
          `Female: ${percentageFemale || 0}%`,
        ],
        datasets: [
          {
            label: "total:",
            data: [maleVisits, femaleVisits],
            backgroundColor: ["#1977D1", "#641D48"],
            borderColor: ["#1977D1", "#641D48"],
            borderWidth: 1,
          },
        ],
      };
    }
  }

  return (
    <Grid
      container
      style={{
        background: whiteColor,
        padding: 20,
      }}
    >
      <GridContainer>
        <div className={classes.headContainer}>
          <Typography className={classes.title}> {dataTitle} </Typography>

          <span className={classes.dimensionSelector}>
            {dimensions && (
              <HeaderSelect
                hasSecondary
                onChangeMenu={(selected) => handleDimensionChange(selected)}
                value={state.selectedDimension.name}
                headerMenus={dimensions}
                onChangeSecondaryMenu={(selected) =>
                  onSecondaryDimensionChange(selected)
                }
                secondaryValue={state.selectedSecondaryDimension}
                secondaryHeaderMenus={secondaryDimensions}
              />
            )}
          </span>
        </div>
      </GridContainer>

      {demographicsInfoStore.loading ? (
        <Loading />
      ) : demographicsInfoStore.data.results ? (
        <GridContainer>
          <MultiRecordsDoughnutChartComponent
            title="Gender Ratio"
            data={data}
            width={chartWidth > 500 ? "45%" : "100%"}
          />

          <MultiRecordsChartComponent
            data={formatChartData(
              demographicsInfoStore.data.results
                ? demographicsInfoStore.data.results
                : [],
              `${state.selectedSecondaryDimension}-age-range`
            )}
            width={chartWidth > 500 ? "45%" : "100%"}
            title="Age Range Chart"
          />
        </GridContainer>
      ) : (
        <NoContent title={"No Content"} />
      )}
    </Grid>
  );
};
