import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ListItemMenu from "../../../components/Menu/ListItemMenu";
import { grayColor, primaryColor } from "../../../assets/styles/base";
import { useWindowDimensions } from "../../../helpers/helperMethods";
import Image from "../../../components/Image";

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px",
    "&:hover": {
      backgroundColor: "#F2F2F2",
      borderRadius: "6px",
      cursor: "pointer",
      opacity: 0.98,
    },
  },
  listTextContent: {
    cursor: "pointer",
    width: "80%",
    marginLeft: "3%",
    padding: "0px 10px",

    [theme.breakpoints.down("sm")]: {
      padding: "5px",
      marginLeft: "2%",
    },
  },
  itemImg: {
    borderRadius: "6px",
    width: "200px",
    height: "150px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "70px",
    },
  },
  smallImg: {
    width: "110px",
    height: "70px",
    borderRadius: "3px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "60px",
    },
  },
  itemTitle: {
    fontWeight: "500",
    fontSize: "18px",
  },
  itemSub: {
    color: grayColor[1],
  },
  itemActions: {
    width: "10%",
    marginTop: "6vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: "3vh",
    },
  },
  smallItemActions: {
    marginTop: "2vh",
  },
}));

export default ({ title, subTitle, actionMenu, imgUrl, onClick, isSmall }) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const onContentClick = () => onClick();
  return (
    <div className={classes.listItemRoot}>
      <div className={classes.listImg}>
        <Image
          placeholderImg={`https://via.placeholder.com/200x150.png?text=${title.substring(
            0,
            10
          )}.`}
          src={imgUrl}
          className={isSmall ? classes.smallImg : classes.itemImg}
        />
      </div>
      <div className={classes.listTextContent} onClick={onContentClick}>
        <Typography className={classes.itemTitle}>{title}</Typography>

        {subTitle && <div className={classes.itemSub}>{subTitle}</div>}
      </div>
      <div className={isSmall ? classes.smallItemActions : classes.itemActions}>
        <ListItemMenu menu={actionMenu} />
      </div>
    </div>
  );
};
