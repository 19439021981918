import React, { useState } from "react";
// core-components
import CardHeader from "@material-ui/core/CardHeader";
import PriceList from "../../paymentPlan/PriceList";
import { IconButton, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory } from "react-router";
import { primaryColor } from "../../../../../assets/styles/base";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  back: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "24px",

    "&:hover": {
      cursor: "pointer",
      color: primaryColor[0],
    },
  },
}));

export default ({ onPaymentPlanChoosing, paymentPlans }) => {
  const history = useHistory();
  const classes = useStyles();
  const plans = paymentPlans.map((plan) => ({
    planType: plan.Name,
    price: plan.Amount,
    features: [plan.Name],
  }));

  const handlePlanChoosing = (data) => {
    onPaymentPlanChoosing(data);
  };
  const handleBackToAds = () => history.push("/ads");
  return (
    <div style={{ padding: "0 5%" }}>
      <div className={classes.back} onClick={handleBackToAds}>
        <ChevronLeftIcon fontSize="large" />
        <Typography variant="body1">Back to All Ads</Typography>
      </div>

      <CardHeader title="Choose Payment Plan" />
      <PriceList content={plans} choosePaymentPlan={handlePlanChoosing} />
    </div>
  );
};
