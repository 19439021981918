import React from "react";
import AppLayout from "../../layouts/AppLayout";
import ClientsContainer from "../containers/clients/AllClientsContainer";
import ClientContainer from "../containers/clients/ClientContainer";

export default (props) => {
  const clientId = props.match.params.clientId;
  return clientId ? (
    <AppLayout hasBack>
      <ClientContainer clientId={clientId} />
    </AppLayout>
  ) : (
    <AppLayout>
      <ClientsContainer />
    </AppLayout>
  );
};
