import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CLIENT_INFO,
  GET_CLIENT_PENDIND_INVITE,
  GET_PUBLISHERS,
} from "../../../redux/clients/types";
import { GET_APP_INFO } from "../../../redux/settings/types";
import { settingsAction } from "../../../redux/settings";
import { clientsAction } from "../../../redux/clients";
import Loading from "../../../components/isLoading";
import apiService from "../../../services/apiService";
import identityService from "../../../services/identityService";
import Toast from "../../../components/MessageToast";
import ClientComponent from "../../components/clients/ClientComponent";

export default ({ clientId }) => {
  const dispatch = useDispatch();
  const clientInfo = useSelector((store) => store.clientInfo);
  const appInfo = useSelector((store) => store.appInfo);

  const [state, setState] = useState({});

  useEffect(() => {
    if (!clientInfo.data.results || clientInfo.data.results.id !== clientId)
      dispatch(
        settingsAction({
          method: "GET",
          path: `/api/orgs/${clientId}`,
          actionType: GET_CLIENT_INFO,
        })
      );
    if (!appInfo.data.results)
      dispatch(
        settingsAction({
          method: "GET",
          path: `/api/apps/${process.env.APP_ID}`,
          actionType: GET_APP_INFO,
        })
      );
  }, []);

  const addUserToOrg = async (name, email, role) => {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await identityService({
      method: "POST",
      path: `/api/orgs/${clientId}/users`,
      data: {
        name: name || "",
        email: email,
        role,
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 5000,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error)
          dispatch(
            settingsAction({
              method: "GET",
              path: `/api/orgs/${clientId}`,
              actionType: GET_CLIENT_INFO,
            })
          );
      },
    });
  };

  const onAddPublisher = async (publisher) => {
    const response = await apiService({
      method: "POST",
      path: `/clients/${clientId}/publishers`,
      data: {
        publisherId: publisher.value,
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 5000,
      onClose: () => {
        dispatch(
          clientsAction({
            method: "GET",
            path: `/clients/${clientId}`,
            actionType: GET_CLIENT_INFO,
          })
        );
      },
    });
  };

  const onRemovePublisher = async (publisherId) => {
    const response = await apiService({
      method: "DELETE",
      path: `/clients/${clientId}/publishers/${publisherId}`,
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 5000,
      onClose: () => {
        dispatch(
          clientsAction({
            method: "GET",
            path: `/clients/${clientId}`,
            actionType: GET_CLIENT_INFO,
          })
        );
      },
    });
  };

  const onRemoveUser = async (uid, email, name) => {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await identityService({
      method: "DELETE",
      path: `/api/orgs/${clientId}/users/${uid}`,
      options: {
        data: {
          email,
          name,
        },
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 5000,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error)
          dispatch(
            settingsAction({
              method: "GET",
              path: `api/orgs/${clientId}`,
              actionType: GET_CLIENT_INFO,
            })
          );
      },
    });
  };

  const onChangeUserRole = async (role, email, name) => {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await identityService({
      method: "POST",
      path: `/api/orgs/${clientId}/users`,
      data: {
        role,
        email,
        name,
      },
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 5000,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error)
          dispatch(
            settingsAction({
              method: "GET",
              path: `api/orgs/${clientId}`,
              actionType: GET_CLIENT_INFO,
            })
          );
      },
    });
  };

  return clientInfo.loading ? (
    <Loading />
  ) : (
    <ClientComponent
      client={clientInfo.data.results || {}}
      app={appInfo.data.results || {}}
      onAddUser={addUserToOrg}
      onRemoveUser={onRemoveUser}
      onChangeUserRole={onChangeUserRole}
      loading={state.loading}
    />
  );
};
