import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EditEventComponent from "../../components/events/EditEventComponent";
import reduxAction from "../../../redux/action";
import { FETCH_SINGLE_EVENT_INFO } from "../../../redux/events/types";
import apiService from "../../../services/apiService";
import Toastify from "../../../components/MessageToast";
import Loading from "../../../components/isLoading";
import { folderDate } from "../../../helpers/helperMethods";
import NoContent from "../../../components/NoContent";
import { uploadToStorageService } from "../../../services/uploadService";

export default ({ eventId }) => {
  const dispatch = useDispatch();
  const event = useSelector((store) => store.singleEvent);
  const [eventState, setCampaign] = useState(event);
  const [bannerUpload, setBannerUpload] = useState({
    fullUrl: null,
    loading: false,
    openToast: false,
  });
  const [advertUpload, setAdvertUpload] = useState({
    fullUrl: null,
    loading: false,
    openToast: false,
  });

  const [apiCall, setApiCall] = useState({
    loading: false,
  });

  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("au"));
    const { userOrg, userApp } = user.claims;
    setCurrentUser({
      uid: user.uid,
      email: user.email,
      userApp,
      userOrg,
    });
    if (!event.data.results || eventId !== event.data.results.id) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/events/${eventId}`,
          actionType: FETCH_SINGLE_EVENT_INFO,
        })
      );
    }
  }, []);

  useEffect(() => {
    setCampaign({ ...eventState, ...event.data });
  }, [event.data]);

  const onBasicInforChange = async (eventInfo) => {
    setApiCall({
      loading: true,
    });
    const response = await apiService({
      path: `/events/${eventId}`,
      method: "PATCH",
      data: eventInfo,
    });
    Toastify({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setApiCall({
          loading: false,
        });
        dispatch(
          reduxAction({
            method: "GET",
            path: `/events/${eventId}`,
            actionType: FETCH_SINGLE_EVENT_INFO,
          })
        );
      },
    });
  };

  const onLaunchEvent = async () => {
    setApiCall({
      loading: true,
    });
    const response = await apiService({
      path: `/events/${eventId}`,
      method: "PATCH",
      data: {
        status: "published",
      },
    });
    Toastify({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setApiCall({
          loading: false,
        });
        dispatch(
          reduxAction({
            method: "GET",
            path: `/events/${eventId}`,
            actionType: FETCH_SINGLE_EVENT_INFO,
          })
        );
      },
    });
  };

  const onActivateOrStopEvent = async () => {
    setApiCall({
      loading: true,
    });
    const response = await apiService({
      path: `/events/${eventId}`,
      method: "PATCH",
      data: {
        isActive: !event.data.results.isActive,
      },
    });
    Toastify({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setApiCall({
          loading: false,
        });
        dispatch(
          reduxAction({
            method: "GET",
            path: `/events/${eventId}`,
            actionType: FETCH_SINGLE_EVENT_INFO,
          })
        );
      },
    });
  };

  const onBannerUpload = async (file) => {
    setBannerUpload({
      ...bannerUpload,
      loading: true,
    });

    const destination = `public/images/${event.data.results.name
      .replace(/\s/g, "")
      .substring(0, 6)}/${folderDate}`;

    const fd = new FormData();
    fd.append("image", file);
    fd.append("destination", destination);
    const upload = await uploadToStorageService("/images/upload", fd);

    if (!upload.error) {
      const response = await apiService({
        path: `/events/${eventId}`,
        method: "PATCH",
        data: {
          coverPhoto: upload.response.fullUrl,
        },
      });
      Toastify({
        message: response.message,
        type: response.error ? "error" : "success",
        duration: 4000,
        onClose: () => {
          setBannerUpload({
            ...bannerUpload,
            loading: false,
            error: response.error && "Failed uploading image, please try again",
          });
          dispatch(
            reduxAction({
              method: "GET",
              path: `/events/${eventId}`,
              actionType: FETCH_SINGLE_EVENT_INFO,
            })
          );
        },
      });
    } else {
      Toastify({
        message: "Failed uploading image, please try again",
        type: "error",
        duration: 4000,
        onClose: () => {
          setBannerUpload({
            ...bannerUpload,
            loading: false,
          });
        },
      });
    }
  };

  const onAddContestant = async (contestantData) => {
    setApiCall({
      loading: true,
    });
    const response = await apiService({
      path: `/events/${eventId}/candidates`,
      method: "POST",
      data: { ...contestantData, lastName: contestantData.lastName || "" },
    });
    if (!response.error) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/events/${eventId}`,
          actionType: FETCH_SINGLE_EVENT_INFO,
        })
      );
    }
    Toastify({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setApiCall({
          loading: false,
        });
      },
    });
  };

  const editContestant = async (contestantData) => {
    setApiCall({
      loading: true,
    });
    const response = await apiService({
      path: `/events/${eventId}/candidates/${contestantData.id}`,
      method: "PATCH",
      data: { ...contestantData, lastName: contestantData.lastName || "" },
    });
    if (!response.error) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/events/${eventId}`,
          actionType: FETCH_SINGLE_EVENT_INFO,
        })
      );
    }
    Toastify({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setApiCall({
          loading: false,
        });
      },
    });
  };

  const onRemoveContestant = async (contestanId) => {
    if (eventState.results?.status === "published") {
      return Toastify({
        message: "You cannot delete contestant from published event",
        type: "warning",
        duration: 5000,
      });
    }
    setApiCall({
      loading: true,
    });
    const response = await apiService({
      path: `/events/${eventId}/candidates/${contestanId}`,
      method: "DELETE",
    });
    if (!response.error) {
      dispatch(
        reduxAction({
          method: "GET",
          path: `/events/${eventId}`,
          actionType: FETCH_SINGLE_EVENT_INFO,
        })
      );
    }
    Toastify({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setApiCall({
          loading: false,
        });
      },
    });
  };

  return (event.loading && !eventState.results) ||
    (event.loading && eventState.results.id !== eventId) ? (
    <Loading />
  ) : (
    <div>
      {eventState.results ? (
        <EditEventComponent
          event={eventState.results || {}}
          eventId={eventId}
          onBasicInforChange={onBasicInforChange}
          onAddContestant={onAddContestant}
          apiCall={apiCall}
          onBannerUpload={onBannerUpload}
          bannerUploadState={bannerUpload}
          onRemoveContestant={onRemoveContestant}
          editContestant={editContestant}
          onLaunchEvent={onLaunchEvent}
          onActivateOrStopEvent={onActivateOrStopEvent}
        />
      ) : (
        <NoContent
          title={
            event.data.error && event.data.message.includes("connection")
              ? event.data.message
              : "Can Not find event"
          }
        />
      )}
    </div>
  );
};
