import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../../assets/styles/base";
import Button from "../../../../components/Buttons/customButton";
import ListItemMenu from "../../../../components/Menu/ListItemMenu";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";

const useStyles = makeStyles((theme) => ({
  createButton: {
    backgroundColor: primaryColor[0],
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: primaryColor[0],
    },
  },
  coverPhoto: {
    height: theme.spacing(30),
    width: theme.spacing(30),
  },
  card: {
    padding: 10,
  },
}));

export default ({
  onChangeComponentClick,
  contestants,
  onRemoveContestant,
  onEditContestant,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography variant="h6" align="center">
        Event contestants
      </Typography>
      <List>
        {contestants &&
          contestants.map((el) => (
            <ListItem key={el.id}>
              <ListItemAvatar>
                <Avatar src={el.photo} variant="square" alt="Photo" />
              </ListItemAvatar>
              <ListItemText
                primary={`${el.firstName} ${el.lastName}`}
                secondary={`${el.category || ""} | code: ${
                  el.contestantCode || "None"
                }`}
              />
              <ListItemSecondaryAction>
                <ListItemMenu
                  menu={[
                    {
                      icon: <Edit fontSize="small" />,
                      primary: "Edit",
                      onClick: () => onChangeComponentClick("edit", el),
                    },
                    {
                      icon: <BlockIcon fontSize="small" />,
                      primary: el.eliminated ? "Uneliminate" : "Eliminate",
                      onClick: () =>
                        onEditContestant({
                          id: el.id,
                          eliminated: !el.eliminated,
                        }),
                    },
                    {
                      icon: <Delete fontSize="small" />,
                      primary: "Delete",
                      onClick: () => onRemoveContestant(el.id),
                    },
                  ]}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>

      <Grid container justify="flex-end">
        <Button
          variant="contained"
          color="primary"
          className={classes.createButton}
          onClick={() => onChangeComponentClick("add")}
        >
          Add contestant
        </Button>
      </Grid>
    </Card>
  );
};
