import {
  PAYMENT_CONFIRM,
  FETCH_PAYMENT_PLANS,
  OTP_VERIFICATION,
  PAYMENT_VERIFICATION,
} from "./types";

const initialState = {
  loading: false,
  data: {},
};

const initialState2 = {
  loading: false,
  payment_data: {},
  otp_data: {},
};

export default class AdvertReducers {
  static allPaymentPlansReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_PAYMENT_PLANS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_PAYMENT_PLANS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_PAYMENT_PLANS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
  static paymentConfirmationReducer(state = initialState2, action) {
    switch (action.type) {
      case `${PAYMENT_CONFIRM}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${OTP_VERIFICATION}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${PAYMENT_CONFIRM}_FULFILLED`:
        return {
          ...state,
          loading: false,
          payment_data: action.payload,
        };

      case `${OTP_VERIFICATION}_FULFILLED`:
        return {
          ...state,
          loading: false,
          otp_data: action.payload,
        };

      case `${PAYMENT_CONFIRM}_REJECTED` || `${OTP_VERIFICATION}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static paymentVerificationReducer(state = initialState, action) {
    switch (action.type) {
      case `${PAYMENT_VERIFICATION}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${PAYMENT_VERIFICATION}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${PAYMENT_VERIFICATION}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
