import {
  UPLOAD_IMAGE_FILE,
  RESET_IMAGE_STORE,
  BANNER_UPLOADING,
  ADVERTORIAL_UPLOADING,
  POST_IMG_UPLOADING,
} from "../types";

const initialState = {
  loading: false,
  data: {},
  error: {},
};

export const uploadImageFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_IMAGE_STORE:
      return initialState;

    case `${UPLOAD_IMAGE_FILE}_PENDING`:
      return { ...state, data: {}, error: {}, loading: true };

    case `${UPLOAD_IMAGE_FILE}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload.response ? action.payload.response : {},
        error: action.payload.error ? action.payload.errorMessage : {},
      };

    case `${UPLOAD_IMAGE_FILE}_REJECTED`:
      return { ...state, loading: false, data: {}, error: `${action.payload}` };
    default:
      return state;
  }
};

export const uploadCampaignImgReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_IMAGE_STORE:
      return initialState;

    case `${BANNER_UPLOADING}_PENDING`:
      return { ...state, data: {}, error: {}, loading: true };

    case `${ADVERTORIAL_UPLOADING}_PENDING`:
      return { ...state, data: {}, error: {}, loading: true };
    case `${POST_IMG_UPLOADING}_PENDING`:
      return { ...state, data: {}, error: {}, loading: true };

    case `${BANNER_UPLOADING}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: {
          banner: action.payload.data ? action.payload.data : {},
        },
        error: action.payload.error ? action.payload.errorMessage : null,
      };

    case `${ADVERTORIAL_UPLOADING}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: {
          advertorial: action.payload.data ? action.payload.data : {},
        },
        error: action.payload.error ? action.payload.errorMessage : null,
      };
    case `${POST_IMG_UPLOADING}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: { postImg: action.payload?.data || {} },
        error: action.payload.error ? action.payload.errorMessage : null,
      };

    case `${BANNER_UPLOADING}_REJECTED` ||
      `${ADVERTORIAL_UPLOADING}_REJECTED` ||
      `${POST_IMG_UPLOADING}_REJECTED`:
      return { ...state, loading: false, data: {}, error: `${action.payload}` };
    default:
      return state;
  }
};
